import { appAxios } from "../../_shared/axios";
import { AvailableBatchesSearch, BatchDto } from "./batch.models";
class BatchService {
  private basePath = "api/batch/";
  private batches: string;
  private options: string;

  async options$(): Promise<any> {
    if (this.options) return JSON.parse(this.options);

    return await appAxios.get(`${this.basePath}options`).then((res) => {
      this.options = JSON.stringify(res.data);
      return res.data;
    });
  }

  async getAvailableBatches(
    search: AvailableBatchesSearch,
  ): Promise<BatchDto[]> {
    return await appAxios
      .post(`${this.basePath}AvailableBatches`, search)
      .then((res) => {
        this.batches = JSON.stringify(res.data);
        return res.data;
      });
  }

  async batchExists$(search: AvailableBatchesSearch): Promise<boolean> {
    return await appAxios
      .post(`${this.basePath}BatchExists`, search)
      .then((res) => {
        return res.data;
      });
  }
  static get instance() {
    return batchService;
  }
}

const batchService = new BatchService();

export default batchService;
