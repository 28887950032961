import { appAxios } from "../../../_shared/axios";

export interface DashboardDto {
  Today: number;
  Past7Days: number;
  MonthToDate: number;
  DailyStats: VaccineCounts[];
}

export interface VaccineCounts {
  Date: string;
  DayLabel: string;
  Covid: number;
  Flu: number;
  RSV: number;
  Pertussis: number;
  Total: number;
}

class DashboardService {
  private basePath = "api/Dashboard/";

  async getDashboardData$(): Promise<DashboardDto> {
    return (await appAxios.get(`${this.basePath}GetDashboardStats`)).data;
  }
}

const dashboardService = new DashboardService();

export default dashboardService;
