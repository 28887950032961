import { VaccineProgramIds } from "./vaccination.enums";

export function SetTouched(formik, fields: string[]) {
  for (const f of fields) {
    if (f) {
      formik.setFieldTouched(f);
    }
  }
}

export function HasErrors(errors, fields: string[]) {
  if (errors) {
    for (const f of fields) {
      const err = errors[f];
      if (err) return true;
    }
  }
  return false;
}

export function IncorrectInterval(
  lastVaccinationDate: Date,
  vaccineProgramId,
  selectedDate: string = null,
  dayGap: number = 91,
): boolean {
  if (
    lastVaccinationDate &&
    vaccineProgramId &&
    vaccineProgramId == VaccineProgramIds.Covid
  ) {
    const currentVaccinationDate = selectedDate
      ? new Date(selectedDate)
      : new Date();
    let intervalDate = new Date(lastVaccinationDate);
    intervalDate.setDate(lastVaccinationDate.getDate() + dayGap);
    return currentVaccinationDate < intervalDate;
  }
  return false;
}
