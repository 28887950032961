import { appAxios } from "./axios";
import { CachedOptions } from "./shared.models";
import { Option } from "./shared.models";

class OptionService {
  private cachedOptions: string;

  async getCachedOptions$(): Promise<CachedOptions> {
    if (this.cachedOptions) return JSON.parse(this.cachedOptions);

    return await appAxios.get("/api/option/cachedOptions").then((res) => {
      this.cachedOptions = JSON.stringify(res.data);
      return res.data;
    });
  }

  async getCachedOption$(option: string): Promise<Option[]> {
    const allOptions = await this.getCachedOptions$();
    try {
      return allOptions[option];
    } catch (error) {
      throw Error(`No option found matching: ${option}`);
    }
  }

  static get instance() {
    return optionService;
  }
}

const optionService = new OptionService();

export default optionService;
