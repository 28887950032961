import {
  AccountStatusUser,
  ChangeStatus,
  User,
  UserSearchRequest,
  UserSearchResult,
} from "./user.models";
import { appAxios } from "../../_shared/axios";

class UserDbService {
  private basePath = "api/UserDb/";

  async search$(search: UserSearchRequest): Promise<UserSearchResult> {
    return (await appAxios.post(`${this.basePath}search`, search)).data;
  }

  async activate$(list: []): Promise<UserSearchResult> {
    return (await appAxios.post(`${this.basePath}activate`, list)).data;
  }

  async changeStatus$(changeStatus: ChangeStatus): Promise<UserSearchResult> {
    return (await appAxios.post(`${this.basePath}changeStatus`, changeStatus))
      .data;
  }

  async accountStatusUser$(userId: string): Promise<AccountStatusUser> {
    return (
      await appAxios.get(`${this.basePath}accountStatusUser?userId=${userId}`)
    ).data;
  }

  async activateUser$(userId: string): Promise<User> {
    return (await appAxios.get(`${this.basePath}activateUser?userId=${userId}`))
      .data;
  }

  async deactivateUser$(userId: string): Promise<User> {
    return (
      await appAxios.get(`${this.basePath}deactivateUser?userId=${userId}`)
    ).data;
  }

  async deactivatedUserCount$(): Promise<number> {
    return (await appAxios.get(`${this.basePath}deactivatedUserCount`)).data;
  }

  async deactivatedUsers$(): Promise<User[]> {
    return (await appAxios.get(`${this.basePath}DeactivatedUsers`)).data;
  }

  static get instance() {
    return userDbService;
  }
}

const userDbService = new UserDbService();

export default userDbService;
