export enum PatientPaths {
  AddPatient = "/patient/add",
  ConfirmAddPatient = "/patient/add/confirm",
  NhsNumberPatientSearch = "/patient/search/nhs-number",
  PdsPatientSearch = "/patient/search/pds",
  PatientRecordSearch = "/patient/search/records",
  PatientRecord = "/patient/:id",
}

export enum PatientPageTitles {
  AddPatient = "Create a patient",
  ConfirmAddPatient = "Patient - Check and confirm",
  NhsNumberPatientSearch = "Find a patient - Search by NHS number",
  PdsPatientSearch = "Find a patient - Search by demographics",
  PatientRecordSearch = "Find a patient - Search your local records",
  PatientDetails = "Patient details",
}

export enum PatientSearchAnalyticsPrimaryCategory {
  PrimaryCategory = "find-a-patient",
}

export enum PatientSearchNhsNumberAnayticsPageName {
  SubCategory1 = "nhs-number",
}

export enum PatientSearchPdsAnayticsPageName {
  SubCategory1 = "demographics",
}

export enum PatientSearchRecordAnayticsPageName {
  SubCategory1 = "records",
}

export enum AddPatientAnalyticsPrimaryCategory {
  PrimaryCategory = "add-patient",
}

export enum ConfirmAddPatientAnalyticsPageName {
  SubCategory1 = "check",
}
