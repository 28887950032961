import React from "react";
import "./NhsPagination.scss";

export default function NhsPagination({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
}) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const renderNavigationButton = (direction, handleClick) => (
    <div className={`nhsuk-pagination--numbered__${direction}`}>
      <button
        className="nhsuk-pagination--numbered__link nhs-link-button"
        onClick={handleClick}
        rel={direction === "prev" ? "prev" : "next"}
        aria-label={direction === "prev" ? "Previous" : "Next"}
      >
        {direction === "prev" ? (
          <>
            <svg
              className="nhsuk-icon nhsuk-icon__arrow-left"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              width="34"
              height="34"
            >
              <path d="M4.1 12.3l2.7 3c.2.2.5.2.7 0 .1-.1.1-.2.1-.3v-2h11c.6 0 1-.4 1-1s-.4-1-1-1h-11V9c0-.2-.1-.4-.3-.5h-.2c-.1 0-.3.1-.4.2l-2.7 3c0 .2 0 .4.1.6z"></path>
            </svg>
            Previous
          </>
        ) : (
          <>
            Next
            <svg
              className="nhsuk-icon nhsuk-icon__arrow-right"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              width="34"
              height="34"
            >
              <path d="M19.6 11.66l-2.73-3A.51.51 0 0 0 16 9v2H5a1 1 0 0 0 0 2h11v2a.5.5 0 0 0 .32.46.39.39 0 0 0 .18 0 .52.52 0 0 0 .37-.16l2.73-3a.5.5 0 0 0 0-.64z"></path>
            </svg>
          </>
        )}
        <span className="nhsuk-u-visually-hidden">
          {direction === "prev" ? "Previous" : "Next"}
        </span>
      </button>
    </div>
  );

  return (
    <nav
      className="nhsuk-pagination nhsuk-pagination--numbered"
      role="navigation"
      aria-label="Pagination"
    >
      {currentPage > 1 &&
        renderNavigationButton("prev", () => paginate(currentPage - 1))}

      <ul className="nhsuk-pagination__list--numbered">
        {pageNumbers.map((number) => (
          <li
            key={`pageKey${number}`}
            className={`nhsuk-pagination--numbered__item ${
              number === currentPage
                ? "nhsuk-pagination--numbered__item--current"
                : ""
            }`}
          >
            <button
              onClick={() => paginate(number)}
              className="nhsuk-pagination--numbered__link nhs-link-button"
              aria-label={`Page ${number}`}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>

      {currentPage < totalPages &&
        renderNavigationButton("next", () => paginate(currentPage + 1))}
    </nav>
  );
}
