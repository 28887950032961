import React from "react";
import "./layout.scss";
import { useUser } from "../user/UserProvider";
import { Header as NHSHeader } from "nhsuk-react-components";
import { RoleIds, UserPaths } from "../user/user.enums";
import AccessControl from "../../_shared/components/accessControl/AccessControl";
import { SiteVaccinesPaths } from "../site-vaccines/site-vaccines.enums";
import { SiteVaccines } from "../site-vaccines/site-vaccines.models";
import { SessionStorageKeys } from "../../_shared/shared.enums";
import "./layout.scss";
import { Link, useNavigate } from "react-router-dom";
import { SignOut } from "../user/user.functions";

export default function Header() {
  const navigate = useNavigate();
  const user = useUser();
  var hasSelected = JSON.parse(
    sessionStorage.getItem(SessionStorageKeys.HasSelectedOrg),
  );

  const shouldHideNavBar = user?.HasMultipleOrgs && !hasSelected;

  const navigateHome = (e) => {
    e.preventDefault();
    navigate("/home");
  };

  return (
    <NHSHeader transactional>
      <NHSHeader.Container>
        <NHSHeader.Logo href="/" onClick={navigateHome} />
        <NHSHeader.ServiceName href="/" onClick={navigateHome}>
          Record a vaccination
        </NHSHeader.ServiceName>
      </NHSHeader.Container>
      <NHSHeader.Nav>
        {user && !shouldHideNavBar && (
          <>
            <AccessControl
              showUnauthorisedMessage={false}
              requiredRoles={[
                RoleIds.LeadAdministrator,
                RoleIds.Administrator,
                RoleIds.Recorder,
              ]}
            >
              <Link className="nhsuk-header__navigation-link" to="/home">
                Home
              </Link>
            </AccessControl>

            <AccessControl
              showUnauthorisedMessage={false}
              requiredRoles={[
                RoleIds.LeadAdministrator,
                RoleIds.Administrator,
                RoleIds.Recorder,
              ]}
            >
              <Link
                className="nhsuk-header__navigation-link"
                to="/patient/search/nhs-number"
              >
                Find a patient
              </Link>
            </AccessControl>

            <AccessControl
              showUnauthorisedMessage={false}
              requiredRoles={[RoleIds.LeadAdministrator, RoleIds.Administrator]}
            >
              <Link
                className="nhsuk-header__navigation-link"
                to={SiteVaccinesPaths.SiteVaccinesList}
                state={[null, [] as SiteVaccines[]]}
              >
                Vaccines
              </Link>
            </AccessControl>

            <AccessControl
              showUnauthorisedMessage={false}
              requiredRoles={[RoleIds.LeadAdministrator, RoleIds.Administrator]}
            >
              <Link className="nhsuk-header__navigation-link" to="/reports">
                Reports
              </Link>
            </AccessControl>

            <AccessControl
              showUnauthorisedMessage={false}
              requiredRoles={[RoleIds.LeadAdministrator]}
            >
              <Link
                className="nhsuk-header__navigation-link"
                to="/manage-users"
              >
                Manage users
              </Link>
            </AccessControl>

            <Link
              className="cutoff-nav nhsuk-header__navigation-link"
              to={UserPaths.Profile}
            >
              {user?.Email}
            </Link>

            <NHSHeader.NavItem
              href="/"
              onClick={(e) => {
                e.preventDefault();
                SignOut();
              }}
            >
              Log Out
            </NHSHeader.NavItem>
          </>
        )}

        <NHSHeader.NavDropdownMenu />
      </NHSHeader.Nav>
    </NHSHeader>
  );
}
