import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import vaccinationService from "./vaccination.service";
import {
  AssessmentOutcomeIds,
  CareModelIds,
  ConsentTypeIds,
  EligibilityTypeIds,
  LegalMechanismIds,
  VaccinationSteps,
  VaccinationTitles,
  VaccineProgramIds,
} from "./vaccination.enums";
import { object, string, date } from "yup";
import VaccinationProgress from "./VaccinationProgress";
import PatientDetails from "../patient/PatientDetails";
import { RadioValues, PageType } from "../../_shared/shared.enums";
import {
  RemoveTime,
  RemoveValues,
  ScrollToTop,
} from "../../_shared/shared.functions";
import { Vaccination, VaccinationOptions } from "./vaccination.models";
import NhsErrorSummary from "../../_shared/components/NHSUK/NhsErrorSummary";
import NhsBackLink from "../../_shared/components/NHSUK/NhsBackLink";
import { useFormik } from "formik";
import { HasErrors, SetTouched } from "./vaccination.functions";
import batchService from "../batch/batch.service";
import vaccinatorLocationService from "../vaccinator-location/vaccinator-location.service";
import usePageDetails from "./hooks/usePageDetails";
import useCachedPatient from "./hooks/useCachedPatient";
import useVaccinationTitle from "./hooks/useVaccinationTitle";
import "./Vaccination.scss";
import { useUser } from "../user/UserProvider";
import { ValidPostcode } from "../../_shared/shared.validation";
import moment from "moment";
import { NhsdOrganisation } from "../../_shared/shared.models";
import VaccinationStepConfirm from "./steps/VaccinationStepConfirm";
import VaccinationStepVaccine from "./steps/VaccinationStepVaccine";
import VaccinationStepAssessment from "./steps/VaccinationStepAssessment";
import VaccinationStepConsent from "./steps/VaccinationStepConsent";
import VaccinationStepVaccinate from "./steps/VaccinationStepVaccinate";
import { BatchDto } from "../batch/batch.models";

const todaysDate = new Date();
const currentYear = todaysDate.getFullYear();
const currentMonth = todaysDate.getMonth();
const minDate = new Date(currentYear - 1, currentMonth, todaysDate.getDate());
if (isLeapYear(currentYear - 1) && currentMonth === 1) {
  minDate.setDate(28);
}
function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

const formSettings = [
  {
    //Vaccine
    OrganisationId: {
      Validation: string().required(),
    },
    SiteId: {
      Label: "Delivery team",
      get Validation() {
        return string().required(`Select delivery team`);
      },
    },

    PatientId: {
      Validation: string().required(),
    },
    VaccineProgramId: {
      Label: "Vaccine",
      get Validation() {
        return string().required(`${this.Label} is required`);
      },
    },
    ConsentVaccineId: {
      Label: "Vaccine product",
      get Validation() {
        return string().required("Select a vaccine");
      },
    },
  },
  {
    //Assessment
    Eligible: {
      Label: `Is the patient eligible for the vaccine? `,
      get Validation() {
        return string().required(
          `Select 'Yes' if the patient is eligible, or 'No' if they are not`,
        );
      },
    },
    EligibilityTypeId: {
      Label: "Eligibility type",
      get Validation() {
        return string().when("Eligible", {
          is: RadioValues.true,
          then: (schema) => schema.required(`Select eligibility type`),
        });
      },
    },
    StaffRoleId: {
      Label: "Staff role",
      get Validation() {
        return string().when("EligibilityTypeId", {
          is: EligibilityTypeIds.HealthCareWorker,
          then: (schema) => schema.required(`Select a staff role`),
        });
      },
    },
    ExpectedDueDate: {
      Label: "Expected due date",
      get Validation() {
        return date().when(["EligibilityTypeId", "VaccineProgramId"], {
          is: (e, v) =>
            e === EligibilityTypeIds.Pregnancy &&
            (v === VaccineProgramIds.RSV || v === VaccineProgramIds.Pertussis),
          then: (schema) =>
            schema
              .test(
                "required-if-touched",
                `${this.Label} is required`,
                function (value) {
                  const isPregnancy =
                    this.parent.EligibilityTypeId ===
                      EligibilityTypeIds.Pregnancy &&
                    (this.parent.VaccineProgramId === VaccineProgramIds.RSV ||
                      this.parent.VaccineProgramId ===
                        VaccineProgramIds.Pertussis);
                  if (isPregnancy) {
                    return value instanceof Date
                      ? !isNaN(value.getTime())
                      : false;
                  }
                  return true;
                },
              )
              .typeError(`${this.Label} is invalid`),
        });
      },
    },

    AssessmentClinicianId: {
      Label: "Assessing clinician",
      get Validation() {
        return string().required(`Select the ${this.Label.toLowerCase()}`);
      },
    },
    LegalMechanismId: {
      Label: "Legal mechanism",
      get Validation() {
        return string().required(`Select a legal mechanism`);
      },
    },
    AssessmentDate: {
      Label: "Assessment date",
      get Validation() {
        return date()
          .max(todaysDate, "Assessment date must be in the present or past")
          .min(minDate, "Date cannot be older than a year")
          .required(`Select an assessment date`);
      },
    },
    AssessmentOutcomeId: {
      Label: "Assessment outcome",
      get Validation() {
        return string().required(`Select an ${this.Label.toLowerCase()}`);
      },
    },
    AssessmentNoVaccinationReasonId: {
      Label: "Reason vaccine not given",
      get Validation() {
        return string().when("AssessmentOutcomeId", {
          is: AssessmentOutcomeIds.VaccineNotGiven,
          then: (schema) =>
            schema.required(`Select a reason why the vaccine was not given`),
        });
      },
    },
    AssessmentComments: {
      Label: "Comments (optional)",
      get Validation() {
        return string()
          .notRequired()
          .max(500, `${this.Label} must be at most 500 characters`);
      },
    },
  },
  {
    //Consent
    Consented: {
      Label:
        "Does the patient or someone on their behalf consent to the vaccination?",
      get Validation() {
        return string().required(`${this.Label} is required`);
      }, // Haven't changed as impossible to continue without selecting.
    },
    NoConsentReasonId: {
      Label: "No consent reason",
      get Validation() {
        return string().when("Consented", {
          is: RadioValues.false,
          then: (schema) => schema.required(`Select a no consent reason`),
        });
      },
    },
    ConsentTypeId: {
      Label: "Consent given by",
      get Validation() {
        return string().when("Consented", {
          is: RadioValues.true,
          then: (schema) =>
            schema.required(
              this.Label === "Consent given by"
                ? "Select the person giving consent"
                : `${this.Label} is required`,
            ),
        });
      },
    },
    NameOfPersonConsenting: {
      Label: "Name of the person consenting",
      get Validation() {
        return string().when("ConsentTypeId", {
          is: (v) => v && v !== "1",
          then: (s) => s.required(`Enter the ${this.Label.toLowerCase()}`),
        });
      },
    },

    RelationshipToPatient: {
      Label: "Relationship to the patient",
      get Validation() {
        return string().when("ConsentTypeId", {
          is: (v) => v && v != "1",
          then: (s) => s.required(`Enter ${this.Label.toLowerCase()}`),
        });
      },
    },
    ConsentClinicianId: {
      Label: "Consenting clinician",
      get Validation() {
        return string().required(`Select the ${this.Label.toLowerCase()}`);
      },
    },
  },
  {
    //Vaccinate
    Vaccinated: {
      Label: "Have you vaccinated the patient?",
      get Validation() {
        return string().required(
          `Select 'Yes' if you have vaccinated the patient, or 'No' if you haven't`,
        );
      },
    },
    VaccinationDate: {
      Label: "Vaccination date",
      get Validation() {
        return date()
          .max(todaysDate, "Vaccination date must be in the present or past")
          .min(minDate, "Date cannot be older than a year")
          .required(`Select a ${this.Label.toLowerCase()}`);
      },
    },
    NoVaccinationReasonId: {
      Label: "No vaccination reason",
      get Validation() {
        return string().when("Vaccinated", {
          is: RadioValues.false,
          then: (schema) => schema.required(`Select a no vaccination reason`),
        });
      },
    },
    VaccineId: {
      Label: "Vaccine product",
      get Validation() {
        return string().when("Vaccinated", {
          is: RadioValues.true,
          then: (schema) => schema.required(`${this.Label} is required`),
        });
      },
    },
    VaccinationSiteId: {
      Label: "Vaccination site",
      get Validation() {
        return string().when("Vaccinated", {
          is: RadioValues.true,
          then: (schema) => schema.required(`Select a vaccination site`),
        });
      },
    },
    BatchNumber: {
      Label: "Batch number",
      get Validation() {
        return string().when(["Vaccinated"], {
          is: (vaccinated) => vaccinated === RadioValues.true,
          then: (schema) =>
            schema.required(`Select a ${this.Label.toLowerCase()}`),
        });
      },
    },

    BatchExpiryDate: {
      Label: "Batch expiry date",
      get Validation() {
        return string().when("Vaccinated", {
          is: RadioValues.true,
          then: (schema) => schema.required(`Enter a batch expiry date`),
        });
      },
    },
    DoseAmount: {
      Label: "Dose amount (ml)",
      get Validation() {
        return string()
          .matches(/^(0)(.)[1-9]{1}$/, `${this.Label} is incorrect`)
          .when("Vaccinated", {
            is: RadioValues.true,
            then: (schema) => schema.required(`${this.Label} is required`),
          });
      },
    },
    VaccinatingClinicianId: {
      Label: "Vaccinator",
      get Validation() {
        return string().required("Select the " + this.Label?.toLowerCase());
      },
    },
    VaccinationComments: {
      Label: "Comments (optional)",
      get Validation() {
        return string()
          .notRequired()
          .max(500, `${this.Label} must be at most 500 characters`);
      },
    },
    CareModelId: {
      get Validation() {
        return string().when("VaccineProgramId", {
          is: (value) =>
            [VaccineProgramIds.Flu, VaccineProgramIds.Covid].includes(value),
          then: (schema) =>
            schema.required("Select where the vaccination is taking place"),
        });
      },
    },
    CareModelName: {
      get Validation() {
        return string();
      },
    },
    CareHomeOdsCode: {
      get Validation() {
        return string().when("CareModelId", {
          is: CareModelIds.CareHome,
          then: (schema) =>
            schema
              .required("required")
              .max(6, "Maximum number of characters is 6"),
        });
      },
    },

    CareHomeName: {
      get Validation() {
        return string().when("CareModelId", {
          is: (CareModelId) => {
            return CareModelId == CareModelIds.CareHome;
          },
          then: (schema) => schema.required("Enter care home details"),
        });
      },
    },

    CareHomeAddress: {
      get Validation() {
        return string().when("CareModelId", {
          is: CareModelIds.CareHome,
          then: (schema) =>
            schema
              .required("required")
              .max(400, "Maximum number of characters is 400"),
        });
      },
    },
    CareHomePostcode: {
      get Validation() {
        return string().when("CareModelId", {
          is: CareModelIds.CareHome,
          then: (schema) =>
            schema
              .required("required")
              .test("isValidPostcode", "Invalid Postcode", function (value) {
                return ValidPostcode(value);
              }),
        });
      },
    },
  },
];

export default function VaccinationAddEdit() {
  const user = useUser();

  const navigate = useNavigate();
  const { id, parentId, pageType, capitalisedPageType, isAddPage } =
    usePageDetails();

  const formFields = useMemo(() => {
    const obj = {};
    for (const fs of formSettings) {
      for (const f of Object.keys(fs)) {
        obj[f] = fs[f];
      }
    }
    return obj;
  }, []);

  const formObject = useMemo(() => {
    const obj = {};
    for (const f of Object.keys(formFields)) {
      obj[f] = "";
    }
    return obj;
  }, []);

  const validationSchema = useMemo(() => {
    const obj = {};
    for (const fs of formSettings) {
      for (const f of Object.keys(fs)) {
        obj[f] = fs[f]?.Validation;
      }
    }
    return object().shape(obj);
  }, []);

  const [saving, setSaving] = useState(false);
  const [allowEdit, setAllowEdit] = useState(isAddPage);
  const [options, setOptions] = useState<VaccinationOptions | null>(null);
  const [vaccines, setVaccines] = useState([]);
  const [step, setStep] = useState(
    isAddPage ? VaccinationSteps.Vaccine : VaccinationSteps.Confirm,
  );
  const [stepChanged, setStepChanged] = useState(false);
  const [batchOptions, setBatchOptions] = useState<BatchDto[] | null>(null);
  const [batchOptionsLoading, setBatchOptionsLoading] = useState(false);
  const [batchExists, setBatchExists] = useState(null);
  const [eligibilityTypeOptions, setEligibilityTypeOptions] = useState<
    VaccinationOptions["EligibilityTypes"]
  >([]);
  const [activeFields, setActiveFields] = useState(getFields(step));
  const [initialValues, setInitialValues] = useState(formObject);
  const [batchExpiryDate, setBatchExpiryDate] = useState({
    Day: "",
    Month: "",
    Year: "",
  });
  const [expectedDueDate, setExpectedDueDate] = useState({
    Day: "",
    Month: "",
    Year: "",
  });
  const [canShowCareHomeDetails, setCanShowCareHomeDetails] =
    React.useState(false);
  const [noAddressDetailsFound, setNoAddressDetailsFound] =
    React.useState(false);
  const noMatchingRecord = false;
  const [suggestions, setSuggestions] = React.useState([]);
  const { vaccinationTitles, stepTitle } = useVaccinationTitle(
    step,
    capitalisedPageType,
  );
  const [CareHomeCustomError, setCareHomeCustomError] = useState<string>(null);
  const [optionsLoading, setOptionsLoadingLoading] = useState(false);
  const [selectedConsentVaccineName, setSelectedConsentVaccineName] =
    useState<string>(null);

  const form = useFormik({
    initialValues: initialValues as Vaccination,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleContinue,
  });

  const { patient, lastCovidVaccinationDate, age } = useCachedPatient(
    form.values.PatientId,
  );
  const handleCareModelChange = (event) => {
    const value = event.target.value;
    if (value && value == CareModelIds.CareHome) {
      setCanShowCareHomeDetails(true);
    } else {
      setCanShowCareHomeDetails(false);
      removeCareHomeDetails();
    }
  };

  async function getSuggestions(value) {
    if (value.length > 2)
      return await vaccinatorLocationService.nhsdOrganisations$(value);
  }

  async function processValue(value: any) {
    var result = await getSuggestions(value);
    setCareHomeCustomError("");
    setSuggestions(result);
  }
  async function getAddressDetails(data: any) {
    return (await vaccinatorLocationService.nhsdOrganisation$(
      data.OrgId,
    )) as NhsdOrganisation;
  }
  async function handleSuggestionClick(fieldName, data) {
    setCareHomeCustomError(null);
    setNoAddressDetailsFound(false);
    var result = await getAddressDetails(data);

    if (result) {
      let address = result.AddressLine1;
      if (result.AddressLine2) address += ", " + result.AddressLine2;
      if (result.Town) address += ", " + result.Town;

      form.setFieldValue(fieldName, result.Name);
      form.setFieldValue("CareHomeAddress", address);
      form.setFieldValue("CareHomeOdsCode", data.OrgId);
      form.setFieldValue(
        "CareHomePostcode",
        data.PostCode ? data.PostCode : result.PostCode,
      );
    } else {
      form.setFieldValue("CareHomeName", "");
      form.setFieldValue("CareHomeOdsCode", "");
      form.setFieldValue("CareHomeAddress", "");
      form.setFieldValue("CareHomePostcode", "");
    }
    setSuggestions([]);
  }

  const processConsentVaccineName = (vaccines) => {
    if (
      step > VaccinationSteps.Vaccine &&
      step < VaccinationSteps.Confirm &&
      ((form.values?.VaccineId && form.values?.VaccineId !== "") ||
        form.values?.ConsentVaccineId) &&
      vaccines
    ) {
      const vaccineId =
        form.values?.VaccineId && form.values?.VaccineId !== ""
          ? form.values?.VaccineId
          : form.values?.ConsentVaccineId;
      const vaccineName = vaccines?.find((o) => o.VaccineId == vaccineId)?.Name;
      setSelectedConsentVaccineName(vaccineName);
    } else {
      setSelectedConsentVaccineName("");
    }
  };

  useEffect(() => {
    const fetchVaccines = async (siteId, vaccinationDate, vaccineProgramId) => {
      const vaccines =
        vaccineProgramId && step !== VaccinationSteps.Vaccine
          ? await vaccinationService.vaccinesWithActiveBatches$(
              siteId,
              vaccinationDate,
              vaccineProgramId,
            )
          : await vaccinationService.vaccines$(siteId, vaccinationDate);

      setVaccines(vaccines);
      validateAndClearBatchDetails(vaccines);
      processConsentVaccineName(vaccines);
    };

    const siteId = form.values?.SiteId;
    if (!siteId) return;

    let vaccinationDate;

    vaccinationDate =
      step === VaccinationSteps.Vaccinate
        ? form.values?.VaccinationDate || new Date().toISOString().split("T")[0]
        : form?.values.VaccinationDate;

    if (step === VaccinationSteps.Vaccine) {
      vaccinationDate = new Date().toISOString().split("T")[0];
    }

    fetchVaccines(
      siteId,
      vaccinationDate,
      pageType === PageType.Edit || form?.values.VaccineProgramId
        ? form?.values.VaccineProgramId
        : null,
    );
  }, [form.values?.SiteId, form.values?.VaccinationDate, step, pageType]);

  const validateAndClearBatchDetails = (vaccines) => {
    const currentVaccineId = form.values?.VaccineId;
    const vaccineExists = vaccines.some(
      (vaccine) => vaccine.VaccineId.toString() === currentVaccineId,
    );

    if (!vaccineExists) {
      removeBatchDetails();
      setSelectedConsentVaccineName(null);
    }
  };

  /**
   * Set options for the eligibility dropdown based on the selected vaccine program. (field name = EligibilityTypeId)
   * This needs to happen after the options have been loaded, and when the vaccine program has been selected.
   */
  useEffect(() => {
    if (options !== null) {
      setEligibilityTypeOptions(
        options.EligibilityTypes.filter(
          (v) => String(v.VaccineProgramId) === form.values?.VaccineProgramId,
        ),
      );
    }
  }, [options, form.values?.VaccineProgramId]);

  useEffect(() => {
    if (allowEdit) {
      form.setFieldValue("ConsentVaccineId", "");

      form.setFieldValue("ExpectedDueDate", "");
      setTimeout(() => {
        form.setFieldTouched("ExpectedDueDate", false);
      });
      setExpectedDueDate({ Day: "", Month: "", Year: "" });
      form.setFieldError("ConsentVaccineId", undefined);
      form.setFieldTouched("ConsentVaccineId", false, false);

      updateBatchExists();
    }
  }, [form.values?.VaccineProgramId]);

  useEffect(() => {
    if (isAddPage) {
      if (
        form.values?.AssessmentOutcomeId ===
        AssessmentOutcomeIds.VaccineNotGiven
      ) {
        const fields = [
          ...getFields(VaccinationSteps.Consent),
          ...getFields(VaccinationSteps.Vaccinate),
        ];
        RemoveValues(form, fields);
      } else {
        RemoveValues(form, ["AssessmentNoVaccinationReasonId"]);
      }
    }
  }, [form.values?.AssessmentOutcomeId]);

  /**
   * When legal mechanism is changed.
   * If the legal mechanism is PGD, set the consent and vaccinating clinician to the assessment clinician.
   */
  useEffect(() => {
    if (isAddPage) {
      const fields = ["ConsentClinicianId", "VaccinatingClinicianId"];
      if (form.values?.LegalMechanismId === LegalMechanismIds.PGD) {
        const assessmentClinicianId = form.values?.AssessmentClinicianId;
        if (assessmentClinicianId) {
          for (const f of fields) {
            form.setFieldValue(f, assessmentClinicianId);
          }
        }
      } else {
        RemoveValues(form, fields);
      }
    }
  }, [form.values?.LegalMechanismId]);

  /**
   * When step is changed to either Consent or Vaccinate page,
   * and legal mechanism is PGD,
   * set the consent and vaccinating clinician to the assessment clinician.
   */
  useEffect(() => {
    if (
      (isAddPage && step === VaccinationSteps.Consent) ||
      step === VaccinationSteps.Vaccinate
    ) {
      if (form.values?.LegalMechanismId === LegalMechanismIds.PGD) {
        const assessmentClinicianId = form.values?.AssessmentClinicianId;
        if (assessmentClinicianId) {
          form.setFieldValue("ConsentClinicianId", assessmentClinicianId);
          form.setFieldValue("VaccinatingClinicianId", assessmentClinicianId);
        }
      }
    }
  }, [step]);

  useEffect(() => {
    if (
      PageType.Edit === pageType &&
      form.values.CareModelId === CareModelIds.CareHome.toString()
    ) {
      form.setFieldTouched("CareHomeName", false);
    }
  }, [form.values.CareModelId]);

  useEffect(() => {
    if (form.values?.BatchExpiryDate) {
      const dateArr = form.values?.BatchExpiryDate?.split("-");
      setBatchExpiryDate({
        Year: dateArr[0],
        Month: dateArr[1],
        Day: dateArr[2],
      });
    }
  }, [form.values?.BatchExpiryDate]);

  useEffect(() => {
    if (isAddPage) {
      if (form.values?.Consented === RadioValues.false) {
        const fields = [
          "ConsentTypeId",
          ...getFields(VaccinationSteps.Vaccinate),
        ];
        RemoveValues(form, fields);
      } else {
        RemoveValues(form, ["NoConsentReasonId"]);
      }
    }
  }, [form.values?.Consented]);

  useEffect(() => {
    if (isAddPage) {
      if (
        form.values?.ConsentTypeId ===
        ConsentTypeIds.PatientInformedConsent.toString()
      ) {
        RemoveValues(form, ["NameOfPersonConsenting", "RelationshipToPatient"]);
      }
    }
    form.setFieldTouched("NameOfPersonConsenting", false);
    form.setFieldTouched("RelationshipToPatient", false);
  }, [form.values?.ConsentTypeId]);

  useEffect(() => {
    if (isAddPage) {
      if (form.values?.Vaccinated !== RadioValues.true) {
        const fields = [
          "VaccineId",
          "VaccinationSiteId",
          "BatchNumber",
          "BatchExpiryDate",
          "DoseAmount",
        ];
        RemoveValues(form, fields);
      } else {
        RemoveValues(form, ["NoVaccinationReasonId"]);
      }
    }
  }, [form.values?.Vaccinated, batchOptions]);

  useEffect(() => {
    if (allowEdit) {
      if (form.values?.ConsentVaccineId) {
        form.setFieldError("ConsentVaccineId", undefined);
        form.setFieldTouched("ConsentVaccineId", false, false);
      }

      updateBatchExists();
    }
  }, [form.values?.ConsentVaccineId]);

  useEffect(() => {
    if (allowEdit) {
      if (form.values?.Eligible !== RadioValues.true) {
        form.setFieldValue("EligibilityTypeId", "");
      }
    }
  }, [form.values?.Eligible]);

  useEffect(() => {
    if (allowEdit) {
      if (
        form.values?.EligibilityTypeId !== EligibilityTypeIds.HealthCareWorker
      ) {
        form.setFieldValue("StaffRoleId", "");
      }
      if (form.values?.EligibilityTypeId !== EligibilityTypeIds.Pregnancy) {
        form.setFieldValue("ExpectedDueDate", "");
        setTimeout(() => {
          form.setFieldTouched("ExpectedDueDate", false);
        });
        setExpectedDueDate({ Day: "", Month: "", Year: "" });
      }
    }
  }, [form.values?.EligibilityTypeId]);

  useEffect(() => {
    if (allowEdit) {
      const vaccineId = form.values?.VaccineId;
      let doseAmount = "";
      if (vaccineId) {
        removeBatchDetails();
        doseAmount = vaccines?.find(
          (v) => v.VaccineId == vaccineId,
        )?.DoseAmount;
      }
      form.setFieldValue("DoseAmount", doseAmount);
    }
  }, [form.values?.VaccineId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    vaccinationService.forceRefreshOptions();
    setOptionsLoadingLoading(true);

    if (isAddPage) {
      vaccinationService.options$().then((res) => {
        setOptions(res);
        setOptionsLoadingLoading(false);
      });
    } else {
      vaccinationService.options$(true).then((res) => {
        setOptions(res);
        setOptionsLoadingLoading(false);
      });
    }

    if (isAddPage) {
      form.setFieldValue("PatientId", parentId);
      form.setFieldValue("OrganisationId", user?.OrganisationId);

      const lastVaccination = vaccinationService.getLastVaccination();
      if (lastVaccination) {
        form.setFieldValue("SiteId", lastVaccination?.SiteId);
        form.setFieldValue(
          "AssessmentClinicianId",
          lastVaccination?.AssessmentClinicianId,
        );
        form.setFieldValue("AssessmentDate", lastVaccination?.AssessmentDate);
        form.setFieldValue(
          "ConsentClinicianId",
          lastVaccination?.ConsentClinicianId,
        );
        form.setFieldValue("VaccinationDate", lastVaccination?.VaccinationDate);
        form.setFieldValue(
          "VaccinatingClinicianId",
          lastVaccination?.VaccinatingClinicianId,
        );
      }
    } else {
      vaccinationService.get$(id).then((res) => {
        setInitialValues(res);
        if (res.CareModelId === "5") {
          setCanShowCareHomeDetails(true);
        }
      });
    }
  }, []);

  function getFields(step) {
    const fields = formSettings[step];
    if (fields) return Object.keys(fields);
    return [];
  }

  const handleBack = () => {
    if (isAddPage || step > VaccinationSteps.Vaccinate) {
      changeStep(step - 1);
    } else {
      alert(`${VaccinationTitles.Consent} can't be edited.`);
    }
  };

  function handleContinue() {
    SetTouched(form, activeFields);
    if (!HasErrors(form.errors, activeFields)) {
      changeStep(step + 1);
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    ScrollToTop();
  }

  function handleCustomChange(event) {
    const value = event.target.value;

    form.setFieldValue("CareHomeName", value);

    if (value) {
      const specialCharsRegex = /[!@#$%^&*+();,.?":{}|<>]/g;
      if (value?.trim() !== "" && !specialCharsRegex.test(value)) {
        processValue(value);
      } else {
        setSuggestions([]);
      }
    }
  }

  const handleSaveAndReturn = () => {
    SetTouched(form, activeFields);
    if (!HasErrors(form.errors, activeFields)) {
      save();
    }
  };

  const shouldPresentVaccinationAlert = (): boolean => {
    const assessmentOutcomeToNotVaccinate =
      form.values.AssessmentOutcomeId === AssessmentOutcomeIds.VaccineNotGiven;
    const patientNotVaccinated = form.values.Vaccinated === RadioValues.false;
    const patientDidNotConsent = form.values.Consented === RadioValues.false;
    return (
      !assessmentOutcomeToNotVaccinate &&
      !patientNotVaccinated &&
      !patientDidNotConsent
    );
  };

  const save = async () => {
    setSaving(true);
    const vaccination = await vaccinationService
      .addOrEdit$(pageType, form.values)
      .finally(() => setSaving(false));
    const presentVaccinationAlert = shouldPresentVaccinationAlert();
    navigate(`/patient/search/nhs-number`, {
      state: [
        {
          PatientId: patient?.PatientId,
          RedirectToVaccination: presentVaccinationAlert,
          Name: patient?.FirstName + " " + patient?.LastName,
          Id: vaccination.VaccinationId,
          AuditDateTime: vaccination.AuditDateTime,
        },
      ],
    });
  };

  const onDeleteClick = async () => {
    setSaving(true);
    await vaccinationService.delete$(id).finally(() => setSaving(false));

    navigate(`/patient/${form.values.PatientId}`, {
      state: [{ ActionType: "delete" }],
    });
  };

  const changeStep = (step) => {
    setStepChanged(true);
    setAllowEdit(true);
    const fields = getFields(step);
    setActiveFields(fields);
    setStep(step);
    window.scrollTo(0, 0);
  };

  const updateBatchExists = async () => {
    setBatchExists(null);
    const vaccineProgramId = form.values.VaccineProgramId;
    if (vaccineProgramId) {
      const vaccineId = form.values.ConsentVaccineId;
      const siteId = form.values?.SiteId;
      if (vaccineId && siteId) {
        const date = new Date();
        setBatchExists(
          await batchService.batchExists$({
            SiteId: siteId,
            VaccineId: vaccineId,
            VaccinatedDate: date.toISOString().split("T")[0],
          }),
        );
      }
    }
  };

  const removeBatchDetails = () => {
    RemoveValues(form, ["BatchNumber", "BatchExpiryDate", "DoseAmount"]);
    setBatchExpiryDate({ Day: "", Month: "", Year: "" });

    if (pageType === PageType.Edit) {
      triggerBatchFieldsValidation();
      setTimeout(() => {
        form.setFieldTouched("VaccineId", true);
      });
    }
  };

  useEffect(() => {
    if (
      form.values.VaccinationDate &&
      form.values.VaccineId &&
      form.values.SiteId
    ) {
      setBatchOptionsLoading(true);
      getBatchOptions();
      setBatchOptionsLoading(false);
    }
  }, [form.values.VaccinationDate, form.values.VaccineId, form.values.SiteId]);

  const getBatchOptions = async () => {
    const vaccinatedDate = form.values.VaccinationDate;
    if (vaccinatedDate) {
      const vaccineId = form.values.VaccineId;
      const siteId = form.values?.SiteId;
      if (vaccineId && siteId) {
        const batches = await batchService.getAvailableBatches({
          SiteId: siteId,
          VaccineId: vaccineId,
          VaccinatedDate: vaccinatedDate,
        });
        setBatchOptions(batches);
      }
    }
  };

  const setBatch = (e) => {
    const batchId = e.target.value;
    if (batchId) {
      const availableBatch = batchOptions?.find((b) => b.BatchId == batchId);
      if (availableBatch) {
        form.setFieldValue("BatchNumber", availableBatch.BatchNumber, true);
        form.setFieldValue(
          "BatchExpiryDate",
          RemoveTime(availableBatch.ExpiryDate),
          true,
        );
        const doseAmount = vaccines?.find(
          (v) => v.VaccineId == form.values.VaccineId,
        )?.DoseAmount;
        form.setFieldValue("DoseAmount", doseAmount, true);
        triggerBatchFieldsValidation();
      }
    } else {
      removeBatchDetails();
      triggerBatchFieldsValidation();
    }
  };

  const triggerBatchFieldsValidation = () => {
    setTimeout(() => {
      form.setFieldTouched("BatchNumber", true);
    });
    setTimeout(() => {
      form.setFieldTouched("BatchExpiryDate", true);
    });
    setTimeout(() => {
      form.setFieldTouched("DoseAmount", true);
    });
  };

  const onBatchClick = async () => {
    if (!form.values.VaccinationDate) {
      alert("Please select the date first!");
      return;
    }

    if (!form.values.VaccineId) {
      alert("Please select the vaccine first!");
      return;
    }

    if (!batchOptionsLoading && !batchOptions?.length) {
      alert("No batch found!");
      return;
    }
  };

  const showVaccineWarning = (vaccineId: string) => {
    if (vaccineId) {
      const vaccine = vaccines?.find(
        (v) =>
          v.VaccineId == vaccineId &&
          v.VaccineProgramId == VaccineProgramIds.Covid,
      );
      if (vaccine) {
        return age < vaccine.MinAge || age > vaccine.MaxAge;
      }
    }
    return false;
  };

  const expectedDueDateChange = (e) => {
    const { name, value } = e.target;
    setExpectedDueDate({ ...expectedDueDate, [name]: value });
  };

  useEffect(() => {
    const year = expectedDueDate.Year;
    const month = expectedDueDate.Month?.padStart(2, "0") || "";
    const day = expectedDueDate.Day?.padStart(2, "0") || "";

    const formattedDate = year + "-" + month + "-" + day;

    const isValidDate = moment(formattedDate, "YYYY-MM-DD", true).isValid();

    if (isValidDate) {
      const date = new Date(`${year}-${month}-${day}`);
      form.setFieldValue("ExpectedDueDate", date);
    } else if (
      !expectedDueDate.Day &&
      !expectedDueDate.Month &&
      !expectedDueDate.Year
    ) {
      form.setFieldValue("ExpectedDueDate", "");
    } else {
      form.setFieldValue("ExpectedDueDate", NaN);
    }
  }, [expectedDueDate]);

  const handleCareModelCustomError = () => {
    const { CareModelId, CareHomeOdsCode } = form.values;

    if (CareModelId === CareModelIds.CareHome.toString()) {
      const isCareHomeOdsCodeEmpty = !CareHomeOdsCode;
      const isCareHomeNameErrorUndefined =
        form.errors.CareHomeName === undefined ||
        form.errors.CareHomeName === "";

      if (isCareHomeOdsCodeEmpty && isCareHomeNameErrorUndefined) {
        setCareHomeCustomError("Select care home");
        ScrollToTop();
        return false;
      } else {
        setCareHomeCustomError("");
        return true;
      }
    }

    return true;
  };

  const removeCareHomeDetails = () => {
    setSuggestions(null);
    form.setFieldValue("CareHomeName", "");
    form.setFieldValue("CareHomeOdsCode", "");
    form.setFieldValue("CareHomeAddress", "");
    form.setFieldValue("CareHomePostCode", "");
    setCareHomeCustomError("");
    setTimeout(() => {
      form.setFieldTouched("CareHomeName", true);
    });
  };

  return (
    <>
      {pageType != PageType.Delete && (
        <>
          {step > VaccinationSteps.Vaccine ? (
            <NhsBackLink onClick={handleBack}></NhsBackLink>
          ) : (
            <NhsBackLink
              url={`/patient/${form.values.PatientId}`}
            ></NhsBackLink>
          )}
        </>
      )}

      <div className="row flex-column-reverse flex-md-row">
        <div className="col-md-8">
          <NhsErrorSummary
            form={form}
            activeFields={activeFields}
            customError={CareHomeCustomError}
          ></NhsErrorSummary>
          <fieldset className="nhsuk-fieldset">
            <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--l">
              <h1 className="nhsuk-fieldset__heading">{stepTitle}</h1>
            </legend>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                form.handleSubmit(e);
              }}
            >
              {step == VaccinationSteps.Vaccine && (
                <VaccinationStepVaccine
                  optionsLoading={optionsLoading}
                  options={options}
                  formFields={formFields}
                  form={form}
                  vaccines={vaccines}
                  batchExists={batchExists}
                  showVaccineWarning={showVaccineWarning}
                  lastCovidVaccinationDate={lastCovidVaccinationDate}
                  handleContinue={handleContinue}
                />
              )}

              <div className="nhsuk-grid-row">
                <div className="nhsuk-grid-column-two-thirds">
                  <p className="nhsuk-body-l">{selectedConsentVaccineName}</p>
                </div>
              </div>

              {step === VaccinationSteps.Assessment && (
                <VaccinationStepAssessment
                  formFields={formFields}
                  form={form}
                  eligibilityTypeOptions={eligibilityTypeOptions}
                  options={options}
                  handleContinue={handleContinue}
                  expectedDueDate={expectedDueDate}
                  expectedDueDateChange={expectedDueDateChange}
                  handleSaveAndReturn={handleSaveAndReturn}
                  minDate={minDate}
                />
              )}

              {step === VaccinationSteps.Consent && (
                <VaccinationStepConsent
                  formFields={formFields}
                  form={form}
                  options={options}
                  handleSaveAndReturn={handleSaveAndReturn}
                  handleContinue={handleContinue}
                />
              )}

              {step === VaccinationSteps.Vaccinate && (
                <VaccinationStepVaccinate
                  formFields={formFields}
                  form={form}
                  isAddPage={isAddPage}
                  options={options}
                  minDate={minDate}
                  handleCareModelChange={handleCareModelChange}
                  canShowCareHomeDetails={canShowCareHomeDetails}
                  noAddressDetailsFound={noAddressDetailsFound}
                  noMatchingRecord={noMatchingRecord}
                  CareHomeCustomError={CareHomeCustomError}
                  handleCustomChange={handleCustomChange}
                  suggestions={suggestions}
                  handleSuggestionClick={handleSuggestionClick}
                  removeCareHomeDetails={removeCareHomeDetails}
                  vaccines={vaccines}
                  setSelectedConsentVaccineName={setSelectedConsentVaccineName}
                  showVaccineWarning={showVaccineWarning}
                  lastCovidVaccinationDate={lastCovidVaccinationDate}
                  batchOptions={batchOptions}
                  setBatch={setBatch}
                  batchOptionsLoading={batchOptionsLoading}
                  onBatchClick={onBatchClick}
                  batchExpiryDate={batchExpiryDate}
                  handleSaveAndReturn={handleSaveAndReturn}
                  handleCareModelCustomError={handleCareModelCustomError}
                  handleContinue={handleContinue}
                />
              )}

              {step === VaccinationSteps.Confirm && (
                <VaccinationStepConfirm
                  patient={patient}
                  form={form}
                  isAddPage={isAddPage}
                  pageType={pageType}
                  changeStep={changeStep}
                  save={save}
                  onDeleteClick={onDeleteClick}
                  options={options}
                  formFields={formFields}
                  vaccines={vaccines}
                  saving={saving}
                  stepChanged={stepChanged}
                />
              )}
            </form>
          </fieldset>
        </div>

        <div className="col-md-4">
          <VaccinationProgress
            currentPage={step}
            pages={vaccinationTitles}
          ></VaccinationProgress>
          {step < 4 && patient && (
            <PatientDetails patient={patient}></PatientDetails>
          )}
        </div>
      </div>
    </>
  );
}
