import React from "react";
import { useReportsContext } from "./ReportsRoot";
import NhsCheckboxes from "../../_shared/components/form/NhsCheckboxes";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import BackButton from "../../_shared/components/BackButton";
import useAnalytics from "../analytics/hooks/useAnalytics";

const ReportVaccineSelectionPage: React.FC = () => {
  const { vaccinePrograms, values, onSubmit, guard } = useReportsContext();
  guard(["date"]);
  useDocumentTitle("Report vaccines");
  useAnalytics(["service", "reports", "vaccines"]);

  return (
    <>
      <BackButton />
      {vaccinePrograms.length > 0 && (
        <NhsCheckboxes
          title="Choose vaccine"
          checkboxes={vaccinePrograms.map((vaccine) => ({
            id: `${vaccine.VaccineProgramId}`,
            label: vaccine.Name,
          }))}
          selectedCheckboxes={values.vaccines || []}
          setSelectedCheckboxes={(vaccines) =>
            onSubmit("vaccines", vaccines, "sites")
          }
          errorMessage="Select vaccines"
        />
      )}
    </>
  );
};

export default ReportVaccineSelectionPage;
