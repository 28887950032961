import React, { useEffect, useState } from "react";
import dashboardService, {
  DashboardDto,
  VaccineCounts,
} from "../../_shared/services/dashboard/dashboard.service";
import { useUser } from "../user/UserProvider";
import { Loading } from "../../_shared/components/Loading";
import { Link } from "react-router-dom";
import useAnalytics from "../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import AccessControl from "../../_shared/components/accessControl/AccessControl";
import { RoleIds } from "../user/user.enums";

const Dashboard: React.FC = () => {
  useAnalytics(["service", "home"]);
  useDocumentTitle("Home");
  const [data, setData] = useState<DashboardDto | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const user = useUser();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        const dashboardData = await dashboardService.getDashboardData$();
        setData(dashboardData);
      } catch (err) {
        setError("Failed to fetch dashboard data.");
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return <Loading message="Fetching dashboard data..." />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!data) {
    return <p>No data available.</p>;
  }

  return (
    <>
      <div className="nhsuk-grid-row">
        <div className="nhsuk-grid-column-two-thirds">
          <h1 className="nhsuk-heading-l nhsuk-u-margin-bottom-6">
            {user?.Organisation}
          </h1>
          <h2 className="nhsuk-heading-m">Total vaccinations</h2>
        </div>
      </div>

      {/* Summary Section */}
      <ul className="nhsuk-grid-row nhsuk-card-group">
        <li className="nhsuk-grid-column-one-quarter nhsuk-card-group__item">
          <div className="nhsuk-card">
            <div
              className="nhsuk-card__content"
              id="today-vaccinations"
              data-testid="today-vaccinations"
            >
              <p className="nhsuk-heading-l nhsuk-u-font-size-64 nhsuk-u-margin-bottom-1">
                {data.Today}
              </p>
              Today
            </div>
          </div>
        </li>
        <li className="nhsuk-grid-column-one-quarter nhsuk-card-group__item">
          <div className="nhsuk-card">
            <div
              className="nhsuk-card__content"
              id="week-vaccinations"
              data-testid="week-vaccinations"
            >
              <p className="nhsuk-heading-l nhsuk-u-font-size-64 nhsuk-u-margin-bottom-1">
                {data.Past7Days}
              </p>
              Past 7 days
            </div>
          </div>
        </li>
        <li className="nhsuk-grid-column-one-quarter nhsuk-card-group__item">
          <div className="nhsuk-card">
            <div
              className="nhsuk-card__content"
              id="month-vaccinations"
              data-testid="month-vaccinations"
            >
              <p className="nhsuk-heading-l nhsuk-u-font-size-64 nhsuk-u-margin-bottom-1">
                {data.MonthToDate}
              </p>
              {new Date().toLocaleString("default", { month: "long" })} to date
            </div>
          </div>
        </li>
      </ul>

      {/* Daily Stats Table */}
      <div className="nhsuk-grid-row">
        <div className="nhsuk-grid-column-three-quarters">
          <table className="nhsuk-table">
            <caption className="nhsuk-table__caption nhsuk-table__caption--m">
              Past 7 days by vaccine
            </caption>
            <thead className="nhsuk-table__head">
              <tr>
                <th>Date</th>
                <th className="nhsuk-table__header--numeric">Covid</th>
                <th className="nhsuk-table__header--numeric">Flu</th>
                <th className="nhsuk-table__header--numeric">Pertussis</th>
                <th className="nhsuk-table__header--numeric">RSV</th>
                <th className="nhsuk-table__header--numeric">Total</th>
              </tr>
            </thead>
            <tbody>
              {data && data.DailyStats && data.DailyStats.length > 0 ? (
                data.DailyStats.map((stat: VaccineCounts, index: number) => (
                  <tr key={index}>
                    <th
                      scope="row"
                      className="nhsuk-u-font-weight-normal"
                      id={`day-stat-${index}`}
                      data-testid={`day-stat-${index}`}
                    >
                      {stat.DayLabel}
                    </th>
                    <td
                      className="nhsuk-table__cell nhsuk-table__cell--numeric"
                      id={`covid-stat-${index}`}
                      data-testid={`covid-stat-${index}`}
                    >
                      {stat.Covid}
                    </td>
                    <td
                      className="nhsuk-table__cell nhsuk-table__cell--numeric"
                      id={`flu-stat-${index}`}
                      data-testid={`flu-stat-${index}`}
                    >
                      {stat.Flu}
                    </td>
                    <td
                      className="nhsuk-table__cell nhsuk-table__cell--numeric"
                      id={`pertussis-stat-${index}`}
                      data-testid={`pertussis-stat-${index}`}
                    >
                      {stat.Pertussis}
                    </td>
                    <td
                      className="nhsuk-table__cell nhsuk-table__cell--numeric"
                      id={`rsv-stat-${index}`}
                      data-testid={`rsv-stat-${index}`}
                    >
                      {stat.RSV}
                    </td>
                    <td
                      className="nhsuk-table__cell nhsuk-table__cell--numeric"
                      id={`total-stat-${index}`}
                      data-testid={`total-stat-${index}`}
                    >
                      {stat.Total}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>No daily stats available.</td>
                </tr>
              )}
            </tbody>
          </table>
          <AccessControl
            showUnauthorisedMessage={false}
            requiredRoles={[RoleIds.LeadAdministrator, RoleIds.Administrator]}
          >
            <p>
              For a more detailed breakdown,&nbsp;
              <Link className="anchor-style" to="/reports">
                create a report
              </Link>
            </p>
          </AccessControl>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
