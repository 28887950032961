import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, Col, Row } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import optionService from "../../_shared/option.service";
import { number, object, string } from "yup";
import NhsInput from "../../_shared/components/form/NhsInput";
import NhsSelect from "../../_shared/components/form/NhsSelect";
import { Patient } from "./patient.models";
import { ValidPostcode } from "../../_shared/shared.validation";
import { HandleFocusChange, ScrollToTop } from "../../_shared/shared.functions";
import {
  AddPatientAnalyticsPrimaryCategory,
  PatientPageTitles,
  PatientPaths,
} from "./patient.enums";
import NhsErrorSummary from "../../_shared/components/NHSUK/NhsErrorSummary";
import useAnalytics from "../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";

const formFields = {
  FirstName: {
    Label: "First name",
  },
  LastName: {
    Label: "Last name",
  },
  GenderId: {
    Label: "Gender",
  },
  DateOfBirth: {
    Label: "Date of birth",
  },
  Postcode: {
    Label: "Full postcode",
  },
};

export default function AddPatient() {
  useAnalytics(["service", AddPatientAnalyticsPrimaryCategory.PrimaryCategory]);
  useDocumentTitle(PatientPageTitles.AddPatient);
  const navigate = useNavigate();
  const location = useLocation();

  const [patient, setPatient] = useState(
    location && location.state ? (location.state[0] as Patient) : null,
  );
  const [genderOptions, setGenderOptions] = useState([]);
  const [dateOfBirthError, setDateOfBirthError] = React.useState(false);

  const resetForm = () => {
    setPatient(null);
    setDateOfBirthError(false);
    formik.resetForm();
    ScrollToTop();
  };

  const goToConfirm = (patient) => {
    navigate(PatientPaths.ConfirmAddPatient, {
      state: [patient],
    });
  };

  let dateOfBirth =
    patient !== null && patient?.DateOfBirth !== null
      ? new Date(patient?.DateOfBirth)
      : null;

  const formik = useFormik({
    initialValues: {
      FirstName: patient?.FirstName ?? "",
      LastName: patient?.LastName ?? "",
      DateOfBirth_1: dateOfBirth?.getDate()?.toString() ?? "",
      DateOfBirth_2:
        (dateOfBirth?.getMonth() + 1).toString() !== "NaN"
          ? (dateOfBirth?.getMonth() + 1).toString()
          : "",
      DateOfBirth_3: dateOfBirth?.getFullYear()?.toString() ?? "",
      DateOfBirth: dateOfBirth ?? "",
      GenderId: patient?.GenderId ?? "",
      Gender: patient?.Gender ?? "",
      Postcode: patient?.Postcode ?? "",
    },
    validationSchema: object({
      FirstName: string().required("Enter the first name"),
      LastName: string().required("Enter the last name"),
      GenderId: string().required("Select the gender"),
      Gender: string(),
      Postcode: string()
        .test("isValidPostcode", "Invalid Postcode", function (value) {
          if (value) return ValidPostcode(value);
          else return true;
        })
        .required("Enter the postcode"),
      DateOfBirth_1: number().required("Enter the date of birth"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      setDateOfBirthError(false);

      let patient = values as unknown as Patient;

      patient.FirstName = patient.FirstName.trim();
      patient.LastName = patient.LastName.trim();

      if (
        values.DateOfBirth_1 ||
        values.DateOfBirth_2 ||
        values.DateOfBirth_3
      ) {
        let day = values.DateOfBirth_1;
        let month = values.DateOfBirth_2;
        let year = values.DateOfBirth_3;

        if (/^(d{1,2})$/.test(day)) {
          setDateOfBirthError(true);
          return;
        }

        if (/^(d{1,2})$/.test(month)) {
          setDateOfBirthError(true);
          return;
        }

        if (/^(d{2,4})$/.test(year)) {
          setDateOfBirthError(true);
          return;
        }

        const today = new Date();
        const allowedYear = today.getFullYear();
        if (!year || Number(year) > allowedYear) {
          setDateOfBirthError(true);
          return;
        }

        const inputDate = new Date(year + "-" + month + "-" + day);
        const currentDate = new Date();
        if (inputDate > currentDate) {
          setDateOfBirthError(true);
          return;
        }

        if (Number(month) < 1 || Number(month) > 12) {
          setDateOfBirthError(true);
          return;
        }

        if (Number(day) < 1 || Number(day) > 31) {
          setDateOfBirthError(true);
          return;
        }

        if (
          (Number(month) === 4 ||
            Number(month) === 6 ||
            Number(month) === 9 ||
            Number(month) === 11) &&
          Number(day) === 31
        ) {
          setDateOfBirthError(true);
          return;
        }

        if (Number(month) === 2) {
          const isLeapYear =
            (Number(year) % 4 === 0 && Number(year) % 100 !== 0) ||
            Number(year) % 400 === 0;

          if (Number(day) > 29 || (Number(day) === 29 && !isLeapYear)) {
            setDateOfBirthError(true);
            return;
          }
        }
      }

      if (
        values.DateOfBirth_1 &&
        values.DateOfBirth_2 &&
        values.DateOfBirth_3
      ) {
        let dateOfBirth_1 =
          values.DateOfBirth_1.toString().length === 1
            ? "0" + values.DateOfBirth_1.toString()
            : values.DateOfBirth_1.toString();
        let dateOfBirth_2 =
          values.DateOfBirth_2.toString().length === 1
            ? "0" + values.DateOfBirth_2.toString()
            : values.DateOfBirth_2.toString();
        patient.DateOfBirth =
          values.DateOfBirth_3.toString() +
          "-" +
          dateOfBirth_2 +
          "-" +
          dateOfBirth_1;
      } else {
        setDateOfBirthError(true);
        return;
      }

      patient.Gender = genderOptions.find(
        (g) => Number(g.Id) === Number(values.GenderId),
      )?.Name;

      patient.NhsNumber = null;

      setPatient(patient);
      goToConfirm(patient);
    },
  });

  useEffect(() => {
    ScrollToTop();

    const getGenderOptions = async () => {
      setGenderOptions(await optionService.getCachedOption$("Genders"));
    };
    getGenderOptions();
  }, []);

  const handleWarnings = (e, data) => {
    if (data.DateOfBirth) {
      setDateOfBirthError(false);
      formik.setFieldError("DateOfBirth", null);
    }
  };

  const getActiveFormFields = () => {
    let activeFields = [
      "FirstName",
      "LastName",
      "GenderId",
      "GenderId",
      "Postcode",
      "DateOfBirth_1",
    ];

    return activeFields;
  };

  return (
    <>
      <Link className="nhsuk-back-link__link mt-3 mb-3" to="/home">
        <svg
          className="nhsuk-icon nhsuk-icon__chevron-left"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          aria-hidden="true"
          height="24"
          width="24"
        >
          <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
        </svg>{" "}
        Back
      </Link>

      <div className="row mb-2">
        <div className="col-sm-9">
          <NhsErrorSummary
            form={formik}
            activeFields={getActiveFormFields()}
          ></NhsErrorSummary>
          <div className="nhsuk-grid-column-full">
            <fieldset className="nhsuk-fieldset col">
              <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                <h1 className="nhsuk-fieldset__heading">Create a patient</h1>
              </legend>

              <form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col md="5">
                    <NhsInput
                      name="FirstName"
                      formFields={formFields}
                      formik={formik}
                    ></NhsInput>
                  </Col>
                </Row>
                <Row>
                  <Col md="5">
                    <NhsInput
                      name="LastName"
                      formFields={formFields}
                      formik={formik}
                    ></NhsInput>
                  </Col>
                </Row>
                <NhsSelect
                  name="GenderId"
                  formFields={formFields}
                  formik={formik}
                  options={genderOptions}
                ></NhsSelect>
                <Row>
                  <Col md="4">
                    <NhsInput
                      onInput={(e) =>
                        (e.target.value = ("" + e.target.value).toUpperCase())
                      }
                      name="Postcode"
                      maxLength={8}
                      formFields={formFields}
                      type="Postcode"
                      formik={formik}
                    ></NhsInput>
                  </Col>
                </Row>
                <>
                  <div className="nhsuk-form-group">
                    <fieldset
                      className="nhsuk-fieldset"
                      aria-describedby="DateOfBirth-hint"
                      role="group"
                    >
                      <legend className="nhsuk-fieldset__legend">
                        Date of birth
                      </legend>
                      <div className="nhsuk-hint" id="DateOfBirth-hint">
                        For example, 31 03 1980
                      </div>

                      <div className="nhsuk-date-input" id="DateOfBirth">
                        {dateOfBirthError && (
                          <span
                            key="DateOfBirthIdErrorRequiredSpan"
                            className="nhsuk-error-message"
                            id="DateOfBirthRequiredError"
                          >
                            <span
                              key="DateOfBirthErrorRequiredSpan"
                              className="nhsuk-u-visually-hidden"
                            >
                              Error:
                            </span>{" "}
                            Please enter valid date of birth
                          </span>
                        )}

                        {formik.touched.DateOfBirth_1 &&
                          formik.errors.DateOfBirth_1 && (
                            <span
                              key="DateOfBirthIdErrorRequiredSpan2"
                              className="nhsuk-error-message"
                              id="DateOfBirthRequiredError2"
                            >
                              <span
                                key="DateOfBirthErrorRequiredSpan2"
                                className="nhsuk-u-visually-hidden"
                              >
                                Error:
                              </span>{" "}
                              {formik.errors.DateOfBirth_1}
                            </span>
                          )}

                        <div className="nhsuk-date-input__item">
                          <div className="nhsuk-form-group">
                            <input
                              className="nhsuk-input nhsuk-date-input__input "
                              id="DateOfBirthHidden"
                              name="DateOfBirth"
                              type="hidden"
                              value={
                                formik.values.DateOfBirth_3 +
                                "-" +
                                formik.values.DateOfBirth_2 +
                                "-" +
                                formik.values.DateOfBirth_1
                              }
                              onChange={(e) => {
                                formik.setFieldTouched("DateOfBirth");
                                formik.handleChange(e);
                              }}
                              onBlur={(e) => {
                                formik.setFieldTouched("DateOfBirth");
                                formik.handleBlur(e);
                              }}
                            />
                          </div>
                        </div>
                        <br></br>
                        <br></br>
                        <div className="nhsuk-date-input__item">
                          <div className="nhsuk-form-group">
                            <label
                              className="nhsuk-label nhsuk-date-input__label"
                              htmlFor="DateOfBirth_1"
                            >
                              Day
                            </label>

                            <input
                              className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
                              id="DateOfBirth_1"
                              name="DateOfBirth_1"
                              type="number"
                              inputMode="numeric"
                              value={formik.values.DateOfBirth_1}
                              placeholder="DD"
                              maxLength={2}
                              onChange={(e) => {
                                handleWarnings(e, { DateOfBirth: true });
                                formik.setFieldTouched("DateOfBirth");
                                HandleFocusChange(e);
                                formik.handleChange(e);
                              }}
                              onBlur={(e) => {
                                handleWarnings(e, { DateOfBirth: true });
                                formik.setFieldTouched("DateOfBirth");
                                HandleFocusChange(e);
                                formik.handleBlur(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="nhsuk-date-input__item">
                          <div className="nhsuk-form-group">
                            <label
                              className="nhsuk-label nhsuk-date-input__label"
                              htmlFor="DateOfBirth_2"
                            >
                              Month
                            </label>

                            {formik.touched.DateOfBirth_2 &&
                            formik.errors.DateOfBirth_2 ? (
                              <span
                                key="DateOfBirth_2IdErrorSpan"
                                className="nhsuk-error-message"
                                id="DateOfBirth_2IdError"
                              >
                                <span
                                  key="DateOfBirth_2IdVisuallyHiddenErrorSpan"
                                  className="nhsuk-u-visually-hidden"
                                >
                                  Error:
                                </span>{" "}
                                {formik.errors.DateOfBirth_2.toString()}
                              </span>
                            ) : null}

                            <input
                              className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
                              id="DateOfBirth_2"
                              name="DateOfBirth_2"
                              type="number"
                              inputMode="numeric"
                              value={formik.values.DateOfBirth_2}
                              placeholder="MM"
                              maxLength={2}
                              onChange={(e) => {
                                handleWarnings(e, { DateOfBirth: true });
                                formik.setFieldTouched("DateOfBirth");
                                HandleFocusChange(e);
                                formik.handleChange(e);
                              }}
                              onBlur={(e) => {
                                handleWarnings(e, { DateOfBirth: true });
                                formik.setFieldTouched("DateOfBirth");
                                HandleFocusChange(e);
                                formik.handleBlur(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="nhsuk-date-input__item">
                          <div className="nhsuk-form-group">
                            <label
                              className="nhsuk-label nhsuk-date-input__label"
                              htmlFor="DateOfBirth_3"
                            >
                              Year
                            </label>

                            {formik.touched.DateOfBirth_3 &&
                            formik.errors.DateOfBirth_3 ? (
                              <span
                                key="DateOfBirth_3IdErrorSpan"
                                className="nhsuk-error-message"
                                id="DateOfBirth_3IdError"
                              >
                                <span
                                  key="DateOfBirth_3IdVisuallyHiddenErrorSpan"
                                  className="nhsuk-u-visually-hidden"
                                >
                                  Error:
                                </span>{" "}
                                {formik.errors.DateOfBirth_3.toString()}
                              </span>
                            ) : null}

                            <input
                              className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-4"
                              id="DateOfBirth_3"
                              name="DateOfBirth_3"
                              type="number"
                              inputMode="numeric"
                              value={formik.values.DateOfBirth_3}
                              placeholder="YYYY"
                              maxLength={4}
                              onChange={(e) => {
                                handleWarnings(e, { DateOfBirth: true });
                                formik.setFieldTouched("DateOfBirth");
                                HandleFocusChange(e);
                                formik.handleChange(e);
                              }}
                              onBlur={(e) => {
                                handleWarnings(e, { DateOfBirth: true });
                                formik.setFieldTouched("DateOfBirth");
                                HandleFocusChange(e);
                                formik.handleBlur(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </>

                <div className="mt-3">
                  <Button
                    type="button"
                    onClick={resetForm}
                    className="nhsuk-button nhsuk-button--reverse"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={!formik.isValid}
                    className="nhsuk-button float-end"
                  >
                    Check and confirm
                  </Button>
                </div>
              </form>
            </fieldset>
          </div>
        </div>
      </div>
    </>
  );
}
