import { SessionStorageKeys } from "../../_shared/shared.enums";
import { PolicyIds, RoleIds, UserPaths } from "./user.enums";
import { Policies, User } from "./user.models";
import { Option } from "../../_shared/shared.models";
import { Redirect } from "../../_shared/shared.functions";

function ClearSessionStorage(): void {
  sessionStorage.removeItem(SessionStorageKeys.User);
  sessionStorage.removeItem(SessionStorageKeys.HasSelectedOrg);
  sessionStorage.removeItem(SessionStorageKeys.VaccinatorLocation);
  sessionStorage.removeItem(SessionStorageKeys.LastVaccination);
}

export function roleList(): Option[] {
  return [
    { Id: RoleIds.Administrator, Name: "Administrator" },
    { Id: RoleIds.LeadAdministrator, Name: "Lead administrator" },
    { Id: RoleIds.Recorder, Name: "Recorder" },
    { Id: RoleIds.RegionalAdministrator, Name: "Regional administrator" },
  ];
}

export function SetPolicies(user: User): void {
  const policies = [
    {
      Id: PolicyIds.CanManageUsers,
      Roles: [RoleIds.LeadAdministrator],
    },
    {
      Id: PolicyIds.CanDeactivateUsers,
      Roles: [RoleIds.LeadAdministrator],
    },
  ] as Policies[];

  if (user.Roles) {
    user.Policies = [];
    for (const p of policies) {
      if (user.Roles.some((r) => p.Roles.includes(r))) user.Policies.push(p.Id);
    }
  }
}

export function SignOut() {
  Promise.all([ClearSessionStorage()])
    .then(() => {
      Redirect(UserPaths.ApiLogout);
    })
    .catch((error) => {
      console.error("Error during sign out:", error);
    });
}
