export enum SessionStorageKeys {
  User = "User",
  VaccinatorLocation = "VaccinatorLocation",
  HasSelectedOrg = "HasSelectedOrg",
  LastVaccination = "LastVaccination",
}

export enum PageType {
  Add = "add",
  Edit = "edit",
  Delete = "delete",
}

export enum RadioValues {
  true = "true",
  false = "false",
}

export enum Paths {
  Home = "/home",
}

export enum PageTitles {
  ContactUs = "Contact us",
  ReportIssue = "Report an issue",
  HelpAndGuidance = "Help and guidance",
}

export enum NotifyTemplateType {
  NewUserSignUpWithOktaActivation = 0,
  NewUserSignUpWithoutOktaActivation = 1,
  LeadUserWithoutOktaActivation = 2,
  LeadUserWithOktaActivation = 3,
}
