import { appAxios } from "../../../_shared/axios";
import {
  Organisation,
  OrganisationExistenceInfo,
} from "../../../components/regional/models/organisation.models";

export class OrganisationService {
  private basePath = "api/organisation";

  async getOrganisationsByRegion$(regionid: number): Promise<Organisation[]> {
    return (
      await appAxios.get(
        `${this.basePath}/GetOrganisationByRegion?regionId=${regionid}`,
      )
    ).data;
  }

  async addOrganisation$(organisation: Organisation): Promise<Organisation> {
    return (
      await appAxios.post(`${this.basePath}/AddOrganisation`, organisation)
    ).data;
  }

  async checkOrganisation$(
    odsCode: string,
  ): Promise<OrganisationExistenceInfo> {
    return (
      await appAxios.get(
        `${this.basePath}/CheckOrganisation?odsCode=${odsCode}`,
      )
    ).data;
  }

  async getOrganisation$(orgId: number): Promise<Organisation> {
    return (await appAxios.get(`${this.basePath}/GetOrganisation?id=${orgId}`))
      .data;
  }

  static get instance() {
    return organisationService;
  }
}

const organisationService = new OrganisationService();

export default organisationService;
