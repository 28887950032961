import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import { useUser } from "../user/UserProvider";
import reportsService, {
  GenerateReportParams,
  SiteName,
  VaccineProgramName,
} from "../../_shared/services/reports/reports.service";
import AccessControl from "../../_shared/components/accessControl/AccessControl";
import { RoleIds } from "../user/user.enums";

interface ReportValues {
  date: DateRange | null;
  vaccines: string[] | null;
  sites: string[] | null;
  data: DataOption["value"][];
}

export type ReportContext = {
  sites: SiteName[];
  vaccinePrograms: VaccineProgramName[];
  dataOptions: DataOption[];
  isLoading: boolean;

  values: ReportValues;

  onSubmit<K extends keyof ReportValues>(
    key: K,
    data: ReportValues[K],
    nextPath: string,
  ): void;

  reset: () => void;

  /**
   * Ensure the data keys exist in a truthy state, otherwise redirect to the first step
   */
  guard: (keys: (keyof ReportValues)[]) => boolean;

  /**
   * The URL of the generated report blob
   */
  reportBlobUrl: string | null;

  /**
   * Creates a blob URL for the report and navigates to the ready page
   */
  createReport: () => Promise<void>;
};

const LOCAL_STORAGE_KEY = "reports";

interface DateRange {
  dateRangeOption: string;
  fromDate: Date;
  toDate: Date;
}

const fetchSites = async (organisationId: number): Promise<SiteName[]> => {
  try {
    return await reportsService.getAllSites$(organisationId);
  } catch (error) {
    console.error("Failed to fetch sites");
    return [];
  }
};

const fetchVaccinePrograms = async (): Promise<VaccineProgramName[]> => {
  try {
    return await reportsService.getAllVaccinationPrograms$();
  } catch (error) {
    console.error("Failed to fetch vaccine programs");
    return [];
  }
};

type DataOption = {
  value: "patient" | "staff" | "site" | "assessment" | "vaccination";
  label: string;
  hint?: string;
};

const dataOptions: DataOption[] = [
  {
    value: "patient",
    label: "Patients",
    hint: "NHS number, name, date of birth, gender, address",
  },
  {
    value: "staff",
    label: "Staff",
    hint: "Recorder, vaccinator, assessing and consenting clinician names and email address",
  },
  {
    value: "site",
    label: "Site or delivery team",
    hint: "Names and ODS codes",
  },
  {
    value: "assessment",
    label: "Assessment and consent",
    hint: "Vaccinated and not givens, date, consent and eligibility details and comments",
  },
  {
    value: "vaccination",
    label: "Vaccination",
    hint: "Date, where the vaccination took place, vaccine details and dose given, site of body, legal mechanism and comments",
  },
];

export const getReportParams: (values: ReportValues) => GenerateReportParams = (
  values,
) => {
  return {
    siteIds: values.sites || [],
    vaccinationPrograms: values.vaccines || [],
    excludeData: dataOptions
      .map((option) => option.value)
      .filter((id) => !values.data.includes(id)),
    startDate: values.date
      ? values.date.fromDate.toISOString().slice(0, 10)
      : "",
    endDate: values.date ? values.date.toDate.toISOString().slice(0, 10) : "",
  };
};

const getBlankValues = (): ReportValues => ({
  date: null,
  vaccines: null,
  sites: null,
  data: dataOptions.map((option) => option.value),
});

const ReportsRoot: React.FC<React.PropsWithChildren> = ({ children }) => {
  const currentUser = useUser();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [sites, setSites] = useState<SiteName[]>([]);
  const [vaccinePrograms, setVaccinePrograms] = useState<VaccineProgramName[]>(
    [],
  );

  let initialData: ReportValues = getBlankValues();

  // Fetch initial data from local storage, if it exists
  let localStorageData = window.localStorage.getItem(LOCAL_STORAGE_KEY);
  if (localStorageData) {
    initialData = JSON.parse(localStorageData, (key, value) => {
      if (key === "fromDate" || key === "toDate") {
        return new Date(value);
      }
      return value;
    });
  }

  const [values, setValues] = useState(initialData);
  const [reportBlobUrl, setReportBlobUrl] = useState<string | null>(null);

  useEffect(() => {
    if (currentUser && currentUser.OrganisationId) {
      setIsLoading(true);
      Promise.all([
        fetchSites(currentUser.OrganisationId),
        fetchVaccinePrograms(),
      ]).then(([sites, vaccinePrograms]) => {
        setSites(sites);
        setVaccinePrograms(vaccinePrograms);
        setIsLoading(false);
      });
    }
  }, [currentUser]);

  // Reset the form when the component is unmounted
  useEffect(() => {
    return () => {
      context.reset();
    };
  }, []);

  const context: ReportContext = {
    sites,
    vaccinePrograms,
    dataOptions,
    isLoading,

    values,

    onSubmit: (key, data, nextPath) => {
      const newValues = {
        ...values,
        [key]: data,
      };
      setValues(newValues);
      // Also save to local storage
      window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newValues));
      navigate(nextPath); // TODO: maybe this is a bit much? Delegate this to the child components?
    },

    reset: () => {
      setValues(getBlankValues());
      window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    },

    guard: (keys: (keyof ReportValues)[]) => {
      for (const key of keys) {
        if (!values[key]) {
          navigate("/reports", { replace: true });
          return false;
        }
      }
      return true;
    },

    reportBlobUrl,
    createReport: async () => {
      try {
        setIsLoading(true);
        const reportParams = getReportParams(values);
        const reportBlobUrl = await reportsService.generateReport(reportParams);
        setIsLoading(false);
        setReportBlobUrl(reportBlobUrl);
        navigate("/reports/ready");
      } catch (error) {
        console.error("Error downloading report", error);
      }
    },
  };

  return (
    <AccessControl
      requiredRoles={[RoleIds.Administrator, RoleIds.LeadAdministrator]}
    >
      <div className="nhsuk-grid-row">
        <div className="nhsuk-grid-column-two-thirds">
          <Outlet context={context} />
        </div>
      </div>
    </AccessControl>
  );
};

export default ReportsRoot;

export const useReportsContext = () => useOutletContext<ReportContext>();
