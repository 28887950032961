export enum LegalMechanismIds {
  PGD = "2",
  WI = "4",
}

export enum AssessmentOutcomeIds {
  VaccineNotGiven = "2",
}

export enum VaccinationTitles {
  Vaccine = "Choose vaccine",
  Assessment = "Assess the patient",
  Consent = "Record consent",
  Vaccinate = "Vaccinate",
  Confirm = "Check and confirm",
}

export enum VaccinationSteps {
  Vaccine = 0,
  Assessment = 1,
  Consent = 2,
  Vaccinate = 3,
  Confirm = 4,
}

export enum VaccineProgramIds {
  Covid = "1",
  Flu = "2",
  RSV = "3",
  Pertussis = "4",
}

export enum ClinicianRoleIds {
  VaccinatingClinician = 1,
  AssessmentClinician = 2,
  ConsentClinician = 3,
}

export enum ConsentTypeIds {
  PatientInformedConsent = 1,
}

export enum EligibilityTypeIds {
  HealthCareWorker = "3",
  Pregnancy = "6",
}

export enum VaccinationAnalyticsSubCategory1s {
  Vaccine = "choose-vaccine",
  Assessment = "assess",
  Consent = "consent",
  Vaccinate = "vaccinate",
  Confirm = "check",
}

export enum CareModelIds {
  CareHome = 5,
}
