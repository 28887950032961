import React from "react";
import { useReportsContext } from "./ReportsRoot";
import NhsCheckboxes from "../../_shared/components/form/NhsCheckboxes";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import BackButton from "../../_shared/components/BackButton";
import useAnalytics from "../analytics/hooks/useAnalytics";

const ReportSiteSelectionPage: React.FC = () => {
  const { sites, values, onSubmit, guard } = useReportsContext();
  useDocumentTitle("Report sites");
  useAnalytics(["service", "reports", "sites"]);

  guard(["date", "vaccines"]);

  return (
    <>
      <BackButton />
      {sites.length > 0 && (
        <NhsCheckboxes
          title="Choose site"
          checkboxes={sites.map((site) => ({
            id: `${site.SiteId}`,
            label: site.Name,
          }))}
          selectedCheckboxes={values.sites || []}
          setSelectedCheckboxes={(sites) => onSubmit("sites", sites, "data")}
          errorMessage="Select sites"
        />
      )}
    </>
  );
};

export default ReportSiteSelectionPage;
