import React from "react";
import { PageTitles } from "../../_shared/shared.enums";
import useAnalytics from "../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";

export default function ContactUs() {
  useAnalytics(["service", "contact-us"]);
  useDocumentTitle(PageTitles.ContactUs);

  return (
    <div className="nhsuk-grid-row">
      <div className="nhsuk-grid-column-two-thirds">
        <h1 className="nhsuk-heading-xl">Contact us</h1>
        <p>
          Telephone: <b>0121 611 0187</b> (select option 3)
          <br />
          Email:
          <a href="mailto:ravs.support@england.nhs.uk">
            ravs.support@england.nhs.uk
          </a>
        </p>
        <p>Monday to Friday: 8am to 6pm</p>
      </div>
    </div>
  );
}
