import React from "react";
import { Button } from "reactstrap";
import {
  AssessmentOutcomeIds,
  ClinicianRoleIds,
  EligibilityTypeIds,
  LegalMechanismIds,
  VaccineProgramIds,
} from "../vaccination.enums";
import { RadioValues } from "../../../_shared/shared.enums";
import NhsOptionRadio from "../../../_shared/components/form/NhsOptionRadio";
import NhsYesNo from "../../../_shared/components/form/NhsYesNo";
import NhsSelect from "../../../_shared/components/form/NhsSelect";
import NhsTextArea from "../../../_shared/components/form/NhsTextArea";
import NhsDateInput from "../../../_shared/components/form/NhsDateInput";

interface VaccinationStepAssessmentProps {
  formFields: {};
  form: any;
  eligibilityTypeOptions: any;
  options: any;
  handleContinue: () => void;
  expectedDueDate: { Day: string; Month: string; Year: string };
  expectedDueDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSaveAndReturn: () => void;
  minDate: Date;
}

const VaccinationStepAssessment = ({
  formFields,
  form,
  eligibilityTypeOptions,
  options,
  handleContinue,
  expectedDueDate,
  expectedDueDateChange,
  handleSaveAndReturn,
  minDate,
}: VaccinationStepAssessmentProps) => {
  const legalMechanismHints = [
    { id: LegalMechanismIds.WI, hint: "Occupational health only" },
  ];

  function showRsvExpectedDueDateWarning() {
    if (
      form?.values.ExpectedDueDate &&
      form?.values.AssessmentDate &&
      form?.values.VaccineProgramId &&
      form?.values.VaccineProgramId === VaccineProgramIds.RSV
    ) {
      const maxDate = new Date(form?.values.AssessmentDate);
      maxDate.setDate(maxDate.getDate() + 82);

      const expectedDueDate = new Date(form?.values.ExpectedDueDate);
      return expectedDueDate > maxDate;
    }
    return false;
  }

  function showPertussisExpectedDueDateWarning() {
    if (
      form?.values.ExpectedDueDate &&
      form?.values.AssessmentDate &&
      form?.values.VaccineProgramId &&
      form?.values.VaccineProgramId === VaccineProgramIds.Pertussis
    ) {
      const minDate = new Date(form?.values.AssessmentDate);
      minDate.setDate(minDate.getDate() + 55);

      const maxDate = new Date(form?.values.AssessmentDate);
      maxDate.setDate(maxDate.getDate() + 166);

      const expectedDueDate = new Date(form?.values.ExpectedDueDate);
      return expectedDueDate <= minDate || expectedDueDate > maxDate;
    }
    return false;
  }

  return (
    <>
      {/* <h3>Check the patient is suitable for vaccination</h3> */}
      <NhsYesNo
        name="Eligible"
        formFields={formFields}
        formik={form}
      ></NhsYesNo>
      {form.values?.Eligible === RadioValues.true && (
        <NhsSelect
          autoSelectSingleOption={true}
          name="EligibilityTypeId"
          options={eligibilityTypeOptions}
          formFields={formFields}
          formik={form}
          disabled={eligibilityTypeOptions?.length === 1}
        ></NhsSelect>
      )}
      {form.values?.EligibilityTypeId ===
        EligibilityTypeIds.HealthCareWorker && (
        <NhsSelect
          name="StaffRoleId"
          options={options?.StaffRoles}
          formFields={formFields}
          formik={form}
        ></NhsSelect>
      )}

      {form.values?.EligibilityTypeId === EligibilityTypeIds.Pregnancy &&
        (form.values?.VaccineProgramId === VaccineProgramIds.RSV ||
          form.values?.VaccineProgramId === VaccineProgramIds.Pertussis) && (
          <>
            <div className="nhsuk-form-group">
              <fieldset
                className="nhsuk-fieldset"
                aria-describedby="Expected due date"
                role="group"
              >
                <legend className="nhsuk-fieldset__legend nhsuk-label--l mb-0">
                  <label htmlFor="ExpectedDueDate" className="nhsuk-label">
                    {formFields["ExpectedDueDate"]?.Label}
                  </label>
                </legend>

                <div
                  className="nhsuk-hint nhsuk-radios__hint ps-0"
                  id="ExpectedDueDateHint"
                >
                  For example, 15 9 2024
                </div>

                {form.touched["ExpectedDueDate"] &&
                  form.errors["ExpectedDueDate"] && (
                    <span
                      className="nhsuk-error-message"
                      id="ExpectedDueDateError"
                    >
                      <span className="nhsuk-u-visually-hidden">Error:</span>{" "}
                      {form.errors["ExpectedDueDate"]}
                    </span>
                  )}
                <div className="nhsuk-date-input" id="ExpectedDueDate">
                  <div className="nhsuk-date-input__item">
                    <div className="nhsuk-form-group">
                      <label
                        className="nhsuk-label nhsuk-date-input__label"
                        htmlFor="ExpectedDueDateDay"
                      >
                        Day
                      </label>
                      <input
                        className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
                        value={expectedDueDate.Day}
                        onChange={expectedDueDateChange}
                        maxLength={2}
                        id="ExpectedDueDateDay"
                        name="Day"
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                      />
                    </div>
                  </div>
                  <div className="nhsuk-date-input__item">
                    <div className="nhsuk-form-group">
                      <label
                        className="nhsuk-label nhsuk-date-input__label"
                        htmlFor="ExpectedDueDateMonth"
                      >
                        Month
                      </label>
                      <input
                        className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
                        value={expectedDueDate.Month}
                        onChange={expectedDueDateChange}
                        maxLength={2}
                        id="ExpectedDueDateMonth"
                        name="Month"
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                      />
                    </div>
                  </div>
                  <div className="nhsuk-date-input__item">
                    <div className="nhsuk-form-group">
                      <label
                        className="nhsuk-label nhsuk-date-input__label"
                        htmlFor="ExpectedDueDateYear"
                      >
                        Year
                      </label>
                      <input
                        className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-4"
                        value={expectedDueDate.Year}
                        onChange={expectedDueDateChange}
                        maxLength={4}
                        id="ExpectedDueDateYear"
                        name="Year"
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            {showRsvExpectedDueDateWarning() && (
              <div className="nhsuk-warning-callout">
                <h2 className="nhsuk-warning-callout__label">
                  <span role="text">
                    <span className="nhsuk-u-visually-hidden">Important: </span>
                    Warning
                  </span>
                </h2>
                <p>
                  You have indicated the due date is{" "}
                  {`${expectedDueDate.Day}/${expectedDueDate.Month}/${expectedDueDate.Year}`}
                  . This vaccine is not routinely recommended before 28 weeks of
                  pregnancy. For vaccination guidance, visit{" "}
                  <a
                    href="https://assets.publishing.service.gov.uk/media/669a5e37ab418ab05559290d/Green-book-chapter-27a-RSV-18_7_24.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Respiratory syncytial virus (RSV): The Green Book, chapter
                    27a.
                  </a>
                </p>
              </div>
            )}

            {showPertussisExpectedDueDateWarning() && (
              <div className="nhsuk-warning-callout">
                <h2 className="nhsuk-warning-callout__label">
                  <span role="text">
                    <span className="nhsuk-u-visually-hidden">Important: </span>
                    Warning
                  </span>
                </h2>
                <p>
                  The vaccine is recommended between 16 to 32 weeks of
                  pregnancy. For guidance, visit{" "}
                  <a
                    href="https://assets.publishing.service.gov.uk/media/667dee0bc7f64e2342090116/Green_Book_Chapter_24_260624.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Pertussis: the green book, chapter 24.
                  </a>
                </p>
              </div>
            )}
          </>
        )}

      <NhsDateInput
        name="AssessmentDate"
        formFields={formFields}
        formik={form}
        min={minDate.toJSON().slice(0, 10)}
        max={new Date().toJSON().slice(0, 10)}
      ></NhsDateInput>

      <NhsOptionRadio
        name="LegalMechanismId"
        options={options?.LegalMechanisms}
        optionHints={legalMechanismHints}
        formFields={formFields}
        formik={form}
      ></NhsOptionRadio>
      <NhsSelect
        name="AssessmentClinicianId"
        options={options?.Clinicians.filter((c) =>
          c.Roles.some((r) => r == ClinicianRoleIds.AssessmentClinician),
        )}
        optionValue={"ClinicianId"}
        formFields={formFields}
        formik={form}
      ></NhsSelect>

      {form.values.VaccineProgramId === VaccineProgramIds.Covid && (
        <details className="nhsuk-details">
          <summary className="nhsuk-details__summary">
            <span className="nhsuk-details__summary-text">
              Screening considerations
            </span>
          </summary>

          <div className="nhsuk-details__text">
            <ul>
              <li>
                Does the patient have a history of anaphylaxis or significant
                allergic reactions to any vaccines or their ingredients?
              </li>
              <li>
                Has the patient had a serious adverse reaction after the
                COVID-19 vaccine?
              </li>
              <li>Is the patient pregnant or could they be?</li>
            </ul>
          </div>
        </details>
      )}

      <NhsOptionRadio
        name="AssessmentOutcomeId"
        formFields={formFields}
        options={options?.AssessmentOutcomes}
        formik={form}
      ></NhsOptionRadio>

      {form.values?.AssessmentOutcomeId ===
      AssessmentOutcomeIds.VaccineNotGiven ? (
        <>
          <NhsSelect
            name="AssessmentNoVaccinationReasonId"
            options={options?.NoVaccinationReasons}
            formFields={formFields}
            formik={form}
          ></NhsSelect>
          <NhsTextArea
            name="AssessmentComments"
            rows={3}
            formFields={formFields}
            formik={form}
          ></NhsTextArea>
          <Button
            type="button"
            className="nhsuk-button nhsuk-button--secondary float-start"
            data-module="nhsuk-button"
            onClick={handleSaveAndReturn}
          >
            Save and return
          </Button>
        </>
      ) : (
        <>
          <NhsTextArea
            name="AssessmentComments"
            rows={3}
            formFields={formFields}
            formik={form}
          ></NhsTextArea>
          <div className="nhsuk-form-group">
            <Button
              type="button"
              className="nhsuk-button"
              data-module="nhsuk-button"
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default VaccinationStepAssessment;
