import React from "react";
import { Button } from "reactstrap";
import {
  ClinicianRoleIds,
  LegalMechanismIds,
  VaccineProgramIds,
} from "../vaccination.enums";
import { RadioValues } from "../../../_shared/shared.enums";
import NhsOptionRadio from "../../../_shared/components/form/NhsOptionRadio";
import NhsYesNo from "../../../_shared/components/form/NhsYesNo";
import NhsSelect from "../../../_shared/components/form/NhsSelect";
import NhsInput from "../../../_shared/components/form/NhsInput";
import { IsoToUkDate, toTitleCase } from "../../../_shared/shared.functions";
import { IncorrectInterval } from "../vaccination.functions";
import NhsTextArea from "../../../_shared/components/form/NhsTextArea";
import NhsDateInput from "../../../_shared/components/form/NhsDateInput";
import NhsAutocompleteInput from "../../../_shared/components/form/nhs-autocomplete-input/NhsAutocompleteInput";
import NhsAutocompleteSuggestions from "../../../_shared/components/form/nhs-autocomplete-input/NhsAutocompleteSuggestions";

interface VaccinationStepVaccinateProps {
  formFields: any;
  form: any;
  isAddPage: boolean;
  options: any;
  minDate: Date;
  handleCareModelChange: (e: any) => void;
  canShowCareHomeDetails: boolean;
  noAddressDetailsFound: boolean;
  noMatchingRecord: boolean;
  CareHomeCustomError: string;
  handleCustomChange: (e: any) => void;
  suggestions: any;
  handleSuggestionClick: (fieldName: any, data: any) => Promise<void>;
  removeCareHomeDetails: () => void;
  vaccines: any;
  setSelectedConsentVaccineName: (vaccineName: string) => void;
  showVaccineWarning: (vaccineId: string) => boolean;
  lastCovidVaccinationDate: Date;
  batchOptions: any;
  setBatch: (e: any) => void;
  batchOptionsLoading: boolean;
  onBatchClick: (e: any) => void;
  batchExpiryDate: {
    Day: string;
    Month: string;
    Year: string;
  };
  handleSaveAndReturn: () => void;
  handleCareModelCustomError: () => boolean;
  handleContinue: () => void;
}

const VaccinationStepVaccinate = ({
  formFields,
  form,
  isAddPage,
  options,
  minDate,
  handleCareModelChange,
  canShowCareHomeDetails,
  noAddressDetailsFound,
  noMatchingRecord,
  CareHomeCustomError,
  handleCustomChange,
  suggestions,
  handleSuggestionClick,
  removeCareHomeDetails,
  vaccines,
  setSelectedConsentVaccineName,
  showVaccineWarning,
  lastCovidVaccinationDate,
  batchOptions,
  setBatch,
  batchOptionsLoading,
  onBatchClick,
  batchExpiryDate,
  handleSaveAndReturn,
  handleCareModelCustomError,
  handleContinue,
}: VaccinationStepVaccinateProps) => {
  return (
    <>
      <NhsYesNo
        name="Vaccinated"
        formFields={formFields}
        formik={form}
        disabled={isAddPage ? false : true}
      ></NhsYesNo>
      {form.values?.Vaccinated === RadioValues.false && (
        <NhsSelect
          name="NoVaccinationReasonId"
          options={options?.NoVaccinationReasons}
          formFields={formFields}
          formik={form}
        ></NhsSelect>
      )}

      <NhsDateInput
        name="VaccinationDate"
        formFields={formFields}
        formik={form}
        min={minDate.toJSON().slice(0, 10)}
        max={new Date().toJSON().slice(0, 10)}
      ></NhsDateInput>

      {(form?.values.VaccineProgramId === VaccineProgramIds.Covid ||
        form?.values.VaccineProgramId === VaccineProgramIds.Flu) && (
        <>
          <div className="nhsuk-form-group">
            <fieldset className="nhsuk-fieldset">
              <legend className="nhsuk-fieldset__legend nhsuk-u-margin-bottom-1">
                Where is the vaccination taking place?
              </legend>
              <div
                className="nhsuk-hint nhsuk-radios__hint ps-0"
                id="CareModelHint"
              >
                This is needed for payment and reporting.
              </div>

              <NhsOptionRadio
                name="CareModelId"
                options={options?.CareModels}
                formFields={formFields}
                formik={form}
                onChange={(e) => {
                  handleCareModelChange(e);
                  form.handleChange(e);
                }}
                onBlur={(e) => {
                  handleCareModelChange(e);
                  form.handleBlur(e);
                }}
              ></NhsOptionRadio>

              {canShowCareHomeDetails ? (
                <div className="nhsuk-u-margin-bottom-4">
                  {noAddressDetailsFound ? (
                    <span
                      className="nhsuk-error-message"
                      id="CareHomeAddressError"
                    >
                      <span className="nhsuk-u-visually-hidden">Error:</span>{" "}
                      Care home address details not found
                    </span>
                  ) : null}

                  {!form.values.CareHomeOdsCode ? (
                    <>
                      <NhsAutocompleteInput
                        label={"Care home"}
                        aria-label="Search by name or ODS code"
                        hint={"Search by name or ODS code"}
                        name="CareHomeName"
                        noMatchingRecord={noMatchingRecord}
                        selectFromDropDown={null}
                        formik={form}
                        customError={CareHomeCustomError}
                        handleCustomChange={handleCustomChange}
                      />
                      {form?.values?.CareHomeName?.length > 2 && (
                        <NhsAutocompleteSuggestions
                          inputValue={form.values.CareHomeName}
                          name="CareHomeName"
                          suggestions={suggestions}
                          handleOnClick={handleSuggestionClick}
                          nodatafoundname="organisation"
                        />
                      )}
                    </>
                  ) : (
                    <div>
                      <p>{toTitleCase(form.values.CareHomeName)}</p>
                      <p>
                        {form.values.CareHomeAddress?.split(",").map(
                          (part, index) => (
                            <span key={index}>
                              {toTitleCase(part)}
                              <br />
                            </span>
                          ),
                        )}
                        <span>{form.values.CareHomePostcode}</span>
                      </p>
                      <p>ODS code {form.values.CareHomeOdsCode}</p>
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          removeCareHomeDetails();
                        }}
                      >
                        Remove
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </fieldset>
          </div>
        </>
      )}

      {form.values?.Vaccinated === RadioValues.true && (
        <>
          <NhsOptionRadio
            onChange={(e) => {
              const vaccineName = vaccines?.find(
                (o) => o.VaccineId == e.target.value,
              )?.Name;
              setSelectedConsentVaccineName(vaccineName);
              form.handleChange(e);
            }}
            name="VaccineId"
            optionValue={"VaccineId"}
            options={vaccines?.filter(
              (v) =>
                v.Assigned &&
                form.values.VaccineProgramId &&
                v.VaccineProgramId == form.values.VaccineProgramId,
            )}
            formFields={formFields}
            formik={form}
          ></NhsOptionRadio>

          {showVaccineWarning(form.values.VaccineId) && (
            <div className="nhsuk-warning-callout">
              <h2 className="nhsuk-warning-callout__label">
                <span role="text">
                  <span className="nhsuk-u-visually-hidden">Important: </span>
                  Warning
                </span>
              </h2>
              <p>
                This vaccine may not be recommended for a person of this age.
                Please check before proceeding or refer to a prescriber for a
                Patient Specific Direction.
              </p>
            </div>
          )}
          {IncorrectInterval(
            lastCovidVaccinationDate,
            form.values.VaccineProgramId,
          ) && (
            <div className="nhsuk-warning-callout">
              <h2 className="nhsuk-warning-callout__label">
                <span role="text">
                  <span className="nhsuk-u-visually-hidden">Important: </span>
                  Warning
                </span>
              </h2>
              <p>
                You may have not reached the minimal interval between COVID-19
                vaccine doses for this patient. This could depend on the
                clinical circumstances. For vaccination guidance, visit{" "}
                <a
                  href="https://assets.publishing.service.gov.uk/media/65d50a1f2197b2001d7fa70e/Greenbook-chapter-14a-20240220.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  COVID-19: The Green Book, chapter 14a.
                </a>
              </p>
            </div>
          )}
          <NhsSelect
            name="VaccinationSiteId"
            options={options?.VaccinationSites}
            formFields={formFields}
            formik={form}
          ></NhsSelect>

          <div
            className={`nhsuk-form-group ${
              form.touched.BatchExpiryDate && form.errors.BatchExpiryDate
                ? "nhsuk-form-group--error"
                : ""
            }`}
          >
            <label htmlFor="BatchNumber" className="nhsuk-label">
              {formFields["BatchNumber"]?.Label}
            </label>

            {form.touched.BatchNumber && form.errors.BatchNumber ? (
              <span className="nhsuk-error-message" id="BatchNumberError">
                <span className="nhsuk-u-visually-hidden">Error:</span>{" "}
                {form.errors["BatchNumber"]}
              </span>
            ) : null}

            {batchOptions?.length ? (
              <select
                id="BatchNumber"
                className="nhsuk-select"
                onChange={setBatch}
              >
                <option key="-1" value=""></option>
                {batchOptions?.map((o, i) => (
                  <option
                    selected={form.values?.BatchNumber === o.BatchNumber}
                    key={i}
                    value={o.BatchId}
                  >
                    {o.BatchNumber} - {IsoToUkDate(o.ExpiryDate)}
                  </option>
                ))}
              </select>
            ) : (
              <div>
                <select
                  id="BatchNumber"
                  name="BatchNumber"
                  className="nhsuk-select"
                  value={form.values.BatchNumber}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  disabled={batchOptionsLoading}
                  onClick={onBatchClick}
                >
                  <option key="0" value="">
                    {batchOptionsLoading ? "Loading..." : ""}
                  </option>
                  {!batchOptionsLoading &&
                    batchOptions?.map((batch, index) => (
                      <option key={index + 1} value={batch.BatchNumber}>
                        {batch.BatchNumber}
                      </option>
                    ))}
                </select>
              </div>
            )}
          </div>

          <div
            className={`nhsuk-form-group ${
              form.touched.BatchExpiryDate && form.errors.BatchExpiryDate
                ? "nhsuk-form-group--error"
                : ""
            }`}
          >
            <fieldset
              className="nhsuk-fieldset"
              aria-describedby="BatchExpiryDateError"
              role="group"
              data-field="BatchExpiryDate"
            >
              {form.touched.BatchExpiryDate && form.errors.BatchExpiryDate && (
                <span className="nhsuk-error-message" id="BatchExpiryDateError">
                  <span className="nhsuk-u-visually-hidden">Error:</span>{" "}
                  {form.errors["BatchExpiryDate"]}
                </span>
              )}

              <legend className="nhsuk-fieldset__legend nhsuk-label--l mb-0">
                <label htmlFor={"BatchExpiryDate"} className="nhsuk-label">
                  {formFields["BatchExpiryDate"]?.Label}
                </label>
              </legend>

              <div className="nhsuk-date-input" id={"BatchExpiryDate"}>
                <div className="nhsuk-date-input__item">
                  <div className="nhsuk-form-group">
                    <label
                      className="nhsuk-label nhsuk-date-input__label"
                      htmlFor="BatchExpiryDate-day"
                    >
                      Day
                    </label>
                    <input
                      className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
                      value={batchExpiryDate.Day}
                      id="BatchExpiryDate-day"
                      name="BatchExpiryDate-day"
                      type="text"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="nhsuk-date-input__item">
                  <div className="nhsuk-form-group">
                    <label
                      className="nhsuk-label nhsuk-date-input__label"
                      htmlFor="BatchExpiryDate-month"
                    >
                      Month
                    </label>
                    <input
                      className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
                      value={batchExpiryDate.Month}
                      id="BatchExpiryDate-month"
                      name="BatchExpiryDate-month"
                      type="text"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="nhsuk-date-input__item">
                  <div className="nhsuk-form-group">
                    <label
                      className="nhsuk-label nhsuk-date-input__label"
                      htmlFor="BatchExpiryDate-year"
                    >
                      Year
                    </label>
                    <input
                      className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-4"
                      value={batchExpiryDate.Year}
                      id="BatchExpiryDate-year"
                      name="BatchExpiryDate-year"
                      type="text"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <NhsInput
            name="DoseAmount"
            formFields={formFields}
            formik={form}
            disabled
            className="nhsuk-select"
          ></NhsInput>
        </>
      )}

      <NhsSelect
        name="VaccinatingClinicianId"
        options={
          form?.values.LegalMechanismId === LegalMechanismIds.PGD
            ? options?.Clinicians
            : options?.Clinicians.filter((c) =>
                c.Roles.some((r) => r == ClinicianRoleIds.VaccinatingClinician),
              )
        }
        optionValue={"ClinicianId"}
        formFields={formFields}
        formik={form}
        disabled={form?.values.LegalMechanismId === LegalMechanismIds.PGD}
      ></NhsSelect>

      {form.values?.Vaccinated === RadioValues.false && (
        <Button
          type="button"
          className="nhsuk-button nhsuk-button--secondary"
          data-module="nhsuk-button"
          onClick={handleSaveAndReturn}
        >
          Save and return
        </Button>
      )}

      <NhsTextArea
        name="VaccinationComments"
        rows={3}
        formFields={formFields}
        formik={form}
      ></NhsTextArea>

      {form.values?.Vaccinated === RadioValues.true && (
        <>
          {form.values?.VaccineProgramId === VaccineProgramIds.Covid && (
            <>
              <div>
                <h2 className="nhsuk-heading-s">Post vaccination advice</h2>
                <p>
                  Did you explain what the patient should expect after their
                  vaccination? Find out about{" "}
                  <a
                    href="https://www.nhs.uk/vaccinations/covid-19-vaccine/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    COVID-19 vaccinations on NHS.UK (opens in new tab)
                  </a>
                  .
                </p>
              </div>

              <div
                style={{
                  borderLeft: "7px solid #005EB8",
                  paddingLeft: "16px",
                  marginBottom: "16px",
                  paddingTop: "10px",
                  paddingBottom: "1px",
                }}
              >
                <p>
                  If the patient has an adverse reaction, you (or the patient)
                  can complete a&nbsp;
                  <a
                    href="https://yellowcard.mhra.gov.uk/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Yellow Card Report (opens a new tab)
                  </a>
                  &nbsp;to make medicines safer.
                </p>
              </div>
            </>
          )}

          {form.values?.VaccineProgramId === VaccineProgramIds.RSV && (
            <>
              <div>
                <h2 className="nhsuk-heading-s">Post vaccination advice</h2>
                <p style={{ fontSize: "1rem" }}>
                  Did you explain what the patient should expect after their
                  vaccination?
                  <br />
                  Find out about{" "}
                  <a
                    href="https://www.nhs.uk/vaccinations/rsv-vaccine/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    RSV vaccinations on NHS.UK (opens in a new tab)
                  </a>
                  .
                </p>
              </div>

              <div
                style={{
                  borderLeft: "7px solid #005EB8",
                  paddingLeft: "16px",
                  marginBottom: "16px",
                  fontSize: "0.875rem",
                  paddingTop: "10px",
                  paddingBottom: "1px",
                }}
              >
                <p style={{ fontSize: "1rem" }}>
                  If the patient has an adverse reaction, you (or the patient)
                  can complete a&nbsp;
                  <a
                    href="https://yellowcard.mhra.gov.uk/"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    Yellow Card Report (opens a new tab)
                  </a>
                  &nbsp;to make medicines safer.
                </p>
              </div>
            </>
          )}

          {form.values?.VaccineProgramId === VaccineProgramIds.Pertussis && (
            <>
              <div>
                <h2 className="nhsuk-heading-s">Post vaccination advice</h2>
                <p style={{ fontSize: "1rem" }}>
                  Did you explain what the patient should expect after their
                  vaccination?
                  <br />
                  Find out about{" "}
                  <a
                    href="https://www.nhs.uk/pregnancy/keeping-well/whooping-cough-vaccination/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Pertussis (whooping cough) vaccinations during pregnancy on
                    NHS.UK (opens in new tab)
                  </a>
                  .
                </p>
              </div>

              <div
                style={{
                  borderLeft: "7px solid #005EB8",
                  paddingLeft: "16px",
                  marginBottom: "1px",
                  fontSize: "0.875rem",
                  paddingTop: "10px",
                  paddingBottom: "1px",
                }}
              >
                <p style={{ fontSize: "1rem" }}>
                  If the patient has an adverse reaction, you (or the patient)
                  can complete a&nbsp;
                  <a
                    href="https://yellowcard.mhra.gov.uk/"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    Yellow Card Report (opens a new tab)
                  </a>
                  &nbsp;to make medicines safer.
                </p>
              </div>
            </>
          )}

          {form.values?.VaccineProgramId === VaccineProgramIds.Flu && (
            <>
              <div>
                <h2 className="nhsuk-heading-s">Post vaccination advice</h2>
                <p style={{ fontSize: "1rem" }}>
                  Did you explain what the patient should expect after their
                  vaccination?
                  <br />
                  Find out about{" "}
                  <a
                    href="https://www.nhs.uk/vaccinations/flu-vaccine/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Flu vaccinations on NHS.UK (opens in new tab)
                  </a>
                  .
                </p>
              </div>

              <div
                style={{
                  borderLeft: "7px solid #005EB8",
                  paddingLeft: "16px",
                  marginBottom: "16px",
                  fontSize: "0.875rem",
                }}
              >
                <p style={{ fontSize: "1rem" }}>
                  If the patient has an adverse reaction, you (or the patient)
                  can complete a&nbsp;
                  <a
                    href="https://yellowcard.mhra.gov.uk/"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    Yellow Card Report (opens a new tab)
                  </a>
                  &nbsp;to make medicines safer.
                </p>
              </div>
            </>
          )}
        </>
      )}

      <div className="nhsuk-form-group">
        <Button
          type="button"
          className="nhsuk-button"
          data-module="nhsuk-button"
          onClick={() => {
            const customValidationCheck = handleCareModelCustomError();
            if (customValidationCheck) {
              handleContinue();
            }
          }}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default VaccinationStepVaccinate;
