import * as React from "react";

export default function NhsAutocompleteInputV2({
  label = null,
  hint = null,
  formik,
  handleCustomChange,
  noMatchingRecord = false,
  customErrorMsg = null,
  ...props
}) {
  const hasError =
    customErrorMsg || (formik.touched[props.name] && formik.errors[props.name]);

  const renderErrorMessage = (message) => (
    <span className="nhsuk-error-message" id={`${props.name}Error`}>
      <span className="nhsuk-u-visually-hidden">Error:</span> {message}
    </span>
  );

  return (
    <div
      className={`nhsuk-form-group ${hasError ? "nhsuk-form-group--error" : ""}`}
      style={{ marginBottom: "0" }}
    >
      {label && (
        <h1 className="nhsuk-label-wrapper">
          <label
            htmlFor={props.id || props.name}
            className="nhsuk-label nhsuk-label--m nhsuk-u-margin-bottom-1"
          >
            {label}
          </label>
        </h1>
      )}

      {hint && (
        <div className="nhsuk-hint" id={`${props.name}-hint`}>
          {hint}
        </div>
      )}

      {formik.touched[props.name] &&
        formik.errors[props.name] &&
        renderErrorMessage(formik.errors[props.name])}
      {noMatchingRecord &&
        renderErrorMessage(
          `No matching record found with ${formik.values[props.name]}`,
        )}
      {customErrorMsg && renderErrorMessage(customErrorMsg)}

      <input
        id={props.name}
        aria-describedby={hint ? `${props.name}-hint` : null}
        {...props}
        type="text"
        value={formik.values[props.name]}
        onChange={(e) => {
          handleCustomChange(e);
          formik.handleChange(e);
        }}
        onBlur={formik.handleBlur}
        className={`nhsuk-input ${hasError ? "nhsuk-input--error" : ""}`}
        placeholder={"Enter 3 or more characters to search"}
      />
    </div>
  );
}
