import React from "react";
import { Link } from "react-router-dom";
import { SignOut } from "../user.functions";

export default function AuthenticationFailed() {
  return (
    <div className="alert alert-warning row" role="alert">
      <div className="col-md mt-2">
        <h6>An error occurred while signing in!</h6>
      </div>
      <div className="col-md-2">
        <Link to={"/home"} className="btn btn-link">
          Back to Home
        </Link>
        <Link
          to={"/"}
          onClick={(e) => {
            e.preventDefault();
            SignOut();
          }}
          className="btn btn-link"
        >
          Log Out
        </Link>
      </div>
    </div>
  );
}
